import { useTranslation } from 'react-i18next';
import { IRemoteConfigLayout } from './types';

export const GetTranslatorSection = (
  translate: (key: string) => string
): IRemoteConfigLayout => {
  return {
    title: translate('translatorTitle'),
    inputList: [
      {
        inputTitle: translate('windowAlignTitle'),
        inputType: 'radio',
        inputValue: 'align',
        hasBorderBottom: true,
        inputOptions: [
          {
            value: 'top',
            label: translate('translatorOptionTop'),
            optionTitle: `${translate('optionTitle')} ${translate(
              'translatorOptionTop'
            )}`,
          },
          {
            value: 'bottom',
            label: translate('translatorOptionBottom'),
            optionTitle: `${translate('optionTitle')} ${translate(
              'translatorOptionBottom'
            )}`,
          },
        ],
      },
      {
        inputTitle: translate('avatarTitle'),
        inputType: 'avatarSelect',
        inputValue: 'avatar',
        hasBorderBottom: true,
        inputOptions: [
          { value: 'HUGO', name: 'Hugo' },
          { value: 'MAYA', name: 'Maya' },
        ],
      },
      {
        inputTitle: translate('backgroundOpacityTitle'),
        inputType: 'radio',
        inputValue: 'opacity',
        inputOptions: [
          {
            value: '100',
            label: '100%',
            optionTitle: `${translate('optionTitle')} 100%`,
          },
          {
            value: '50',
            label: '50%',
            optionTitle: `${translate('optionTitle')} 50%`,
          },
          {
            value: '0',
            label: '0%',
            optionTitle: `${translate('optionTitle')} 0%`,
          },
        ],
      },
    ],
  };
};
