import Dash from '../../../../assets/svg/Dash';
import { isMobile } from '../../../../../infra/services/helper';
import UsersCountRetroMaya from '../../../../assets/svg/usersCountRetroMaya.svg';
import mayaArmsCrossed from '../../../../assets/svg/mayaArmsCrossed.svg';
import {
  Container,
  Content,
  Footer,
  Header,
  ImageLeftContainer,
  ImageRightContainer,
  Paragraphs,
} from './styles';
import { useTranslation } from 'react-i18next';

interface UsersSlideProps {
  isPrintActive?: boolean;
  data: number | string;
}

export const UsersSlide = ({ isPrintActive, data }: UsersSlideProps) => {
  const { t: translate } = useTranslation('UsersSlide');

  return (
    <Container isPrintActive={isPrintActive}>
      {!isPrintActive && (
        <ImageLeftContainer>
          <Dash alt={translate('DashAlt')} />
        </ImageLeftContainer>
      )}
      <Content>
        <Header>{translate('UserTitle')}</Header>
        <Paragraphs>
          <p>{translate('UserFirstBody')}</p>
          <p>{translate('UserFirstHighlighted')}</p>
          <strong>{data}</strong>
          <p>{translate('UserSecondBody')}</p>
        </Paragraphs>
        <Footer>{translate('UserTertiaryBody')}</Footer>
      </Content>
      <ImageRightContainer>
        {isMobile() || isPrintActive ? (
          <img src={UsersCountRetroMaya} alt={translate('UsersCountRetroMaya')} />
        ) : (
          <img src={mayaArmsCrossed} alt={translate('UsersCountRetroMaya')} />
        )}
      </ImageRightContainer>
    </Container>
  );
};
