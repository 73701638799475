/** Libs */
import styled from 'styled-components';

export const TableData = styled.td`
  font: ${({ theme }) => theme.typography.label.smBold};
  color: ${({ theme }) => theme.colors.secondary.default[0]};
  text-align: center;
  user-select: none;
  padding: 15px 0;
`;
