/** Library */
import React from 'react';
import i18n from '../../../../infra/i18n';
import { useTranslation } from 'react-i18next';

/** Services */
import { formatDashboardValue, formatDateRange } from '../../../../infra/services/helper';
import { dashboardUrl } from '../../../../infra/services/constants';

/** Servuces */
import { IReport } from '../../../../infra/services/interfaces';
import { getStoredItem, setStoredItem } from '../../../../infra/services/localStorage';

/** Hooks */
import useTabNavigation from '../../../hooks/useTabNavigation';
import useAuth from '../../../hooks/useAuth';

/** Components */
import TopContainer from '../../../components/TopContainer';
import Overview from '../../../components/Overview';
import Chart from '../../../components/Chart';
import UserTable from '../../../components/UserTable';
import TopAddons from '../../../components/TopAddons';
import Achievement from '../../../components/Achievement';
import FeedbackCard from '../../../components/FeedbackCard';

/**
 * Renderiza o conteúdo do relatório único.
 */
const Report: React.FC<IReport> = ({
  report,
  isAverage,
  filter,
  handleExportCSV,
  handleExportPDF,
  userInfoState,
}) => {
  /** Verifica no localStorage se o FeedbackCard já foi fechado */
  const hasFeedbackBeenClosed =
    getStoredItem<boolean>('remoteConfigFeedbackHidden') || false;
  const [isFeedbackCardOpen, setFeedbackCardOpen] = React.useState(
    !hasFeedbackBeenClosed
  );

  const { handleChangeTab } = useTabNavigation();
  const { authState } = useAuth();
  const {
    period,
    translations,
    translations: { translatedWords },
    insight: { topPages, topStates, topDevices, topAddons, users, addons },
    info: { domain, filterLanguages, token },
    achievements: { translatedWordsAccumulated },
  } = report;

  const { t: translateReport } = useTranslation('Report');

  /** Manipulação do período */
  const periodFormattedFull = formatDateRange(period, i18n.language);

  const dataTopTopAddons = topAddons && topAddons.length > 0 ? topAddons : [];
  const dataTopDevices = topDevices && topDevices.length > 0 ? topDevices : [];
  const dataTopStates = topStates && topStates.length > 0 ? topStates : [];

  const data = {
    translations: formatDashboardValue(translatedWords).formatted,
    users: formatDashboardValue(users).formatted,
    addons: formatDashboardValue(addons && addons > 0 ? addons : 0).formatted,
  };

  const handleLinkClick = () => {
    if (authState.type === 'success') {
      handleChangeTab('/remote-config/:accessKey');
    } else {
      window.open(dashboardUrl, '_self');
    }
  };

  const handleCloseFeedbackCard = () => {
    setFeedbackCardOpen(false);
    /** Armazena no localStorage que o FeedbackCard foi fechado */
    setStoredItem('remoteConfigFeedbackHidden', true);
  };

  return (
    <>
      {isFeedbackCardOpen && (
        <FeedbackCard
          type="info"
          titleText={translateReport('FeedbackCardTitle')}
          text={translateReport('FeedbackCardText')}
          highlightedText={translateReport('FeedbackCardHighlightedText')}
          isOpen={isFeedbackCardOpen}
          onClose={handleCloseFeedbackCard}
          link={translateReport('FeedbackCardLink')}
          onLinkClick={handleLinkClick}
        />
      )}
      <TopContainer
        onClickExportCsv={handleExportCSV}
        onClickExportPdf={handleExportPDF}
        filter={filter}
        domain={domain}
        addons={data.addons}
        report={report}
        userInfoState={userInfoState}
      />
      <Overview isAverageReport={isAverage} data={data} period={periodFormattedFull} />
      <Chart translations={translations} filterLanguages={filterLanguages} />
      <TopAddons data={dataTopTopAddons} period={periodFormattedFull} />
      <UserTable
        token={token}
        isAverage={isAverage}
        topStates={dataTopStates}
        topDevices={dataTopDevices}
        topPages={topPages}
        domain={domain}
        periodFormattedFull={periodFormattedFull}
        period={period}
      />
      <Achievement translationsAccumulated={translatedWordsAccumulated} />
    </>
  );
};

export default Report;
