/** Libs */
import { YIcon, YIconButton } from '@hand-talk/yotta-components';

/** Styles */
import {
  SectionFeedback,
  Paragraph,
  StyledIcon,
  Title,
  TitleContent,
  MobileContent,
  ButtonLink,
} from './styles';

/** Components */
import CustomContainer from '../CustomContainer';

/** Interfaces */
import { IFeedbackCard } from '../../../infra/services/interfaces';

const FeedbackCard: React.FC<IFeedbackCard> = (props) => {
  const { onClose, isOpen, icon, text, titleText, link, onLinkClick, highlightedText } =
    props;

  const renderTextWithHighlight = () => {
    if (!highlightedText || !text.includes(highlightedText)) return text;

    const parts = text.split(highlightedText);

    return (
      <>
        {parts[0]}
        <span className="dark-text">{highlightedText}</span>
        {parts[1]}
      </>
    );
  };

  return (
    <>
      {isOpen && (
        <CustomContainer>
          <SectionFeedback {...props}>
            <MobileContent>
              {icon && <StyledIcon {...props}>{icon}</StyledIcon>}
              <TitleContent>
                <Title>{titleText}</Title>
                <Paragraph>{renderTextWithHighlight()}</Paragraph>
                {link && <ButtonLink onClick={() => onLinkClick?.()}>{link}</ButtonLink>}
              </TitleContent>
            </MobileContent>
            <YIconButton
              onClick={onClose}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignSelf: 'flex-start',
              }}
            >
              <YIcon name="close" />
            </YIconButton>
          </SectionFeedback>
        </CustomContainer>
      )}
    </>
  );
};

export default FeedbackCard;
