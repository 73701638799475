import { useEffect, useState } from 'react';
import expandedMoreIcon from '../../assets/svg/expandMore.svg';
import expandedLessIcon from '../../assets/svg/expandLess.svg';
import { CollapseHeader, CollapseContainer, CollapseHeaderButton } from './styles';
import { useTranslation } from 'react-i18next';

interface CollapseCardProps {
  title: string;
  isDisabled?: boolean;
  noBorder?: boolean;
  children: JSX.Element;
}

export const CollapseCard = ({
  title,
  children,
  isDisabled,
  noBorder,
}: CollapseCardProps) => {
  const { t: translation } = useTranslation('CollpseCard');
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeCollapse = () => {
    !isDisabled && setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isDisabled) {
      setIsOpen(false);
    }
  }, [isDisabled]);

  return (
    <CollapseContainer isOpen={isOpen} isDisabled={isDisabled} noBorder={noBorder}>
      <CollapseHeader>
        <h3>{title}</h3>
        <CollapseHeaderButton onClick={handleChangeCollapse} isDisabled={isDisabled}>
          <img
            src={isOpen ? expandedLessIcon : expandedMoreIcon}
            alt={
              (!isOpen ? translation('openCardAlt') : translation('closeCardAlt')) +
              ` ${title}`
            }
          />
        </CollapseHeaderButton>
      </CollapseHeader>
      {isOpen ? <>{children}</> : <></>}
    </CollapseContainer>
  );
};
