/** Libs */
import React from 'react';
import {
  getMonth,
  getYear,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  eachMonthOfInterval,
  subMonths,
} from 'date-fns';

/** Services */
import { Timestamp } from '../../../../infra/services/Timestamp';
import { getFormattedMonth } from '../../../../infra/utils/dateUtils';

/** Hooks */
import useLocale from '../../../hooks/useLocale';

interface DateObject {
  year: number;
  month: number;
}

type DateRange = [DateObject | null, DateObject | null];

interface IUseMonthRange {
  messages: { invalidPeriod: string };
  onChange: (value: [Timestamp, Timestamp]) => void;
  onDisableDay?: (dateTimestamp: Timestamp) => boolean;
  selected?: [Timestamp, Timestamp];
  initialYear?: number;
}

const useMonthRange = ({
  messages,
  onChange,
  onDisableDay,
  selected,
  initialYear = new Date().getFullYear(),
}: IUseMonthRange) => {
  const locale = useLocale();
  const [currentYear, setCurrentYear] = React.useState<number>(initialYear);
  /** Estado para armazenar o intervalo com os meses e anos selecionados */
  const [selectedRange, setSelectedRange] = React.useState<DateRange>([null, null]);

  const createRange = React.useCallback(
    (startDate: Date, endDate: Date): DateRange => [
      { year: getYear(startDate), month: getMonth(startDate) },
      { year: getYear(endDate), month: getMonth(endDate) },
    ],
    []
  );

  /** Inicializa o estado com os meses e anos recebidos via props */
  const initializeSelectedRange = React.useCallback(
    (selected?: [Timestamp, Timestamp]) => {
      if (!selected) return;
      const [start, end] = selected;
      const startDate = new Date(start);
      const endDate = new Date(end);

      setSelectedRange(createRange(startDate, endDate));
    },
    [createRange]
  );

  /** Caso nenhum estado seja passado dentro das props, inicia com o mês anterior selecionado */
  const initializePreviousMonth = React.useCallback(() => {
    const previousMonth = subMonths(new Date(), 1);
    const previousYear = getYear(previousMonth);
    const previousMonthIndex = getMonth(previousMonth);

    setSelectedRange(
      createRange(
        new Date(previousYear, previousMonthIndex),
        new Date(previousYear, previousMonthIndex)
      )
    );
  }, [createRange]);

  React.useEffect(() => {
    if (selected) {
      const [, end] = selected;
      const endDate = new Date(end);
      /** Define o ano do último mês do período selecionado */
      setCurrentYear(getYear(endDate));
      initializeSelectedRange(selected);
    } else {
      /** Define o ano inicial como o ano atual */
      setCurrentYear(initialYear);
      initializePreviousMonth();
    }
  }, [selected, initializeSelectedRange, initializePreviousMonth, initialYear]);

  /** Função para verificar se um mês é válido */
  const isMonthValid = React.useCallback(
    (month: number): boolean => {
      /** Se onDisableDay não for fornecido, considere todos válidos */
      if (!onDisableDay) return true;

      const monthStart = startOfMonth(new Date(currentYear, month));
      const monthEnd = endOfMonth(new Date(currentYear, month));

      return eachDayOfInterval({ start: monthStart, end: monthEnd }).some(
        (day) => !onDisableDay(day.getTime() as Timestamp)
      );
    },
    [currentYear, onDisableDay]
  );

  /** Primeiro dia do mês de janeiro do ano atual */
  const startOfInterval = startOfMonth(new Date(currentYear, 0));
  /** Último dia do mês de dezembro do ano atual */
  const endOfInterval = endOfMonth(new Date(currentYear, 11));

  const monthData = eachMonthOfInterval({
    start: startOfInterval,
    end: endOfInterval,
  }).map((month, index) => {
    /** Verifica se o mês está selecionado como início ou fim do intervalo */
    const isSelected =
      (selectedRange[0]?.year === currentYear && selectedRange[0]?.month === index) ||
      (selectedRange[1]?.year === currentYear && selectedRange[1]?.month === index);

    /** Verifica se o mês está dentro do intervalo selecionado */
    const isInRange =
      selectedRange[0] &&
      selectedRange[1] &&
      new Date(currentYear, index) >
        new Date(selectedRange[0].year, selectedRange[0].month) &&
      new Date(currentYear, index) <
        new Date(selectedRange[1].year, selectedRange[1].month);

    const isStart =
      selectedRange[0]?.year === currentYear && selectedRange[0]?.month === index;

    const isEnd =
      selectedRange[1]?.year === currentYear && selectedRange[1]?.month === index;

    /** Formata o nome do mês com base na localização */
    const monthName = getFormattedMonth(month.getTime(), locale);

    return {
      monthName,
      isSelected,
      isInRange,
      isStart,
      isEnd,
      isValid: isMonthValid(index),
      ariaLabel: isMonthValid(index)
        ? `${monthName} ${currentYear}`
        : `${monthName} ${currentYear} - ${messages.invalidPeriod}`,
      index,
    };
  });

  /** Manipula o clique no calendário */
  const handleMonthClick = (index: number) => {
    const clickedDate = new Date(currentYear, index);

    setSelectedRange((prev) => {
      /** Se não houver um mês selecionado, define o início do intervalo */
      if (prev[0] === null) {
        return [{ year: currentYear, month: index }, null];
      }

      /** Se não houver um mês selecionado, define o final do intervalo */
      if (prev[1] === null) {
        const selectedStartDate = new Date(prev[0].year, prev[0].month);

        /** Ordena as datas para criar o intervalo */
        const [start, end] =
          clickedDate >= selectedStartDate
            ? [selectedStartDate, clickedDate]
            : [clickedDate, selectedStartDate];

        /** Atualiza o estado global com o intervalo completo */
        onChange([
          startOfMonth(start).getTime() as Timestamp,
          endOfMonth(end).getTime() as Timestamp,
        ]);

        return [
          { year: getYear(start), month: getMonth(start) },
          { year: getYear(end), month: getMonth(end) },
        ];
      }

      return [{ year: currentYear, month: index }, null];
    });
  };

  /** Funções para navegar entre anos */
  const handlePreviousYear = () => setCurrentYear((prev) => prev - 1);
  const handleNextYear = () => setCurrentYear((prev) => prev + 1);

  return {
    currentYear,
    monthData,
    handleMonthClick,
    handlePreviousYear,
    handleNextYear,
  };
};

export default useMonthRange;
