/** Library */
import React from 'react';
import i18n from '../../../../infra/i18n';
import { useTranslation } from 'react-i18next';

/** Services */
import { formatDashboardValue, formatDateRange } from '../../../../infra/services/helper';
import { dashboardUrl } from '../../../../infra/services/constants';

/** Hooks */
import { UserInfoState } from '../../../hooks/useUserInfo';
import useTabNavigation from '../../../hooks/useTabNavigation';
import useAuth from '../../../hooks/useAuth';

/** Services */
import { IAverageReportSchema } from '../../../../infra/services/interfaces';
import { getStoredItem, setStoredItem } from '../../../../infra/services/localStorage';

/** Components */
import TopContainer from '../../../components/TopContainer';
import Overview from '../../../components/Overview';
import Chart from '../../../components/Chart';
import UserTable from '../../../components/UserTable';
import TopAddons from '../../../components/TopAddons';
import Achievement from '../../../components/Achievement';
import ChartUsers from '../../../components/ChartUsers';
import FeedbackCard from '../../../components/FeedbackCard';

type TAverageReport = {
  averageReport: IAverageReportSchema;
  isAverage: boolean;
  filter: JSX.Element;
  handleExportCSV?: () => void;
  handleExportPDF: () => void;
  userInfoState: UserInfoState;
};

/**
 * Renderiza o conteúdo do relatório único.
 */
const AverageReport: React.FC<TAverageReport> = ({
  averageReport,
  isAverage,
  filter,
  handleExportCSV,
  handleExportPDF,
  userInfoState,
}) => {
  /** Verifica no localStorage se o FeedbackCard já foi fechado */
  const hasFeedbackBeenClosed =
    getStoredItem<boolean>('remoteConfigFeedbackHidden') || false;
  const [isFeedbackCardOpen, setFeedbackCardOpen] = React.useState(
    !hasFeedbackBeenClosed
  );

  const { handleChangeTab } = useTabNavigation();
  const { authState } = useAuth();
  const { t: translateReport } = useTranslation('Report');

  const schema = averageReport;

  const { period, info, achievements, translationHistory, averageInsight } = schema;

  const { domain, filterLanguages, token } = info;
  const { translatedWordsAccumulated } = achievements;
  const { signLanguage, translatedWords } = translationHistory;
  const {
    averageTopDevices,
    averageTopStates,
    averageTopPages,
    topAddons,
    addons,
    averageUsers,
    monthlyUsersInfo,
  } = averageInsight;

  const periodFormattedFull = formatDateRange(period, i18n.language);

  const dataTopAddons =
    topAddons && topAddons.length > 0 ? topAddons.sort((a, b) => b.value - a.value) : [];
  const dataAverageTopDevices =
    averageTopDevices && averageTopDevices.length > 0
      ? averageTopDevices.sort((a, b) => b.value - a.value)
      : [];
  const dataAverageTopState =
    averageTopStates && averageTopStates.length > 0
      ? averageTopStates.sort((a, b) => b.value - a.value).slice(0, 10)
      : [];
  const dataAverageTopPages =
    averageTopPages && averageTopPages.length > 0
      ? averageTopPages.sort((a, b) => b.value - a.value).slice(0, 10)
      : [];

  const data = {
    translations: formatDashboardValue(translatedWords).formatted,
    users: formatDashboardValue(averageUsers).formatted,
    addons: formatDashboardValue(addons && addons > 0 ? addons : 0).formatted,
  };

  const handleLinkClick = () => {
    if (authState.type === 'success') {
      handleChangeTab('/remote-config/:accessKey');
    } else {
      window.open(dashboardUrl, '_self');
    }
  };

  const handleCloseFeedbackCard = () => {
    setFeedbackCardOpen(false);
    /** Armazena no localStorage que o FeedbackCard foi fechado */
    setStoredItem('remoteConfigFeedbackHidden', true);
  };

  return (
    <>
      {isFeedbackCardOpen && (
        <FeedbackCard
          type="info"
          titleText={translateReport('FeedbackCardTitle')}
          text={translateReport('FeedbackCardText')}
          highlightedText={translateReport('FeedbackCardHighlightedText')}
          isOpen={isFeedbackCardOpen}
          onClose={handleCloseFeedbackCard}
          link={translateReport('FeedbackCardLink')}
          onLinkClick={handleLinkClick}
        />
      )}
      <TopContainer
        onClickExportCsv={handleExportCSV}
        onClickExportPdf={handleExportPDF}
        filter={filter}
        domain={domain}
        addons={data.addons}
        report={averageReport}
        userInfoState={userInfoState}
      />
      <Overview isAverageReport={isAverage} data={data} period={periodFormattedFull} />
      <Chart
        translations={{ signLanguage, translatedWords }}
        filterLanguages={filterLanguages}
      />
      <TopAddons data={dataTopAddons} period={periodFormattedFull} />
      <ChartUsers monthlyUsersInfo={monthlyUsersInfo} averageUsers={averageUsers} />
      <UserTable
        token={token}
        topStates={dataAverageTopState}
        topDevices={dataAverageTopDevices}
        topPages={dataAverageTopPages}
        periodFormattedFull={periodFormattedFull}
        period={period}
        domain={domain}
        isAverage={isAverage}
      />
      <Achievement translationsAccumulated={translatedWordsAccumulated} />
    </>
  );
};

export default AverageReport;
