import { YInput, YRadio, YSwitch } from '@hand-talk/yotta-components';
import { InputRadioOption, RemoteConfigInput } from '../../constructor/types';
import { isMobile } from '../../../../../infra/services/helper';
import { InputSelectOption, SelectInput } from '../../../../components/Select';
import { AvatarSwitch } from '../../../../components/AvatarSwitch';
import { Alert } from '../../../../components/Alert';
import { Checkbox } from '../../../../components/Checkbox';
import {
  InputCardSection,
  InputTitleSection,
  SectionCard,
  SwitchInputContent,
} from './styles';

interface IInputConfigCard extends RemoteConfigInput {
  handleChange: (name: string, value: string | number | boolean) => void;
  value: string | number | boolean;
  hasTexture?: boolean;
}

export const InputConfigCard: React.FC<IInputConfigCard> = ({
  inputTitle,
  inputDescription,
  inputType,
  inputOptions,
  placeholder,
  hasBorderBottom,
  inputValue,
  handleChange,
  defaultValue,
  value,
  hasTexture,
}) => {
  return (
    <SectionCard
      className={hasBorderBottom ? `--borderBottom` : ''}
      flexRow={['switch', 'checkbox'].includes(inputType)}
      noVerticalPadding={inputType === 'checkbox'}
      gap={inputType === 'switch' ? 'full' : inputType === 'checkbox' ? undefined : 'sm'}
    >
      {!['error', 'checkbox'].includes(inputType) && (
        <InputTitleSection>
          {inputTitle && <h6>{inputTitle}</h6>}
          {inputDescription && <p>{inputDescription}</p>}
        </InputTitleSection>
      )}
      <InputCardSection>
        {inputType === 'checkbox' && (
          <Checkbox
            name={inputValue}
            onChange={(value) => handleChange(inputValue, value)}
            value={(value || value === false ? value : defaultValue) as boolean}
            label={inputDescription as string}
          />
        )}
        {inputType === 'radio' && (
          <YRadio
            direction={isMobile() ? 'vertical' : 'horizontal'}
            groupTitle="Escolha um lado"
            onChange={({ value }) => handleChange(inputValue, value)}
            choices={inputOptions as InputRadioOption[]}
            selectedOption={
              inputOptions.find(
                (opt) => opt.value === value?.toString()
              ) as InputRadioOption
            }
          />
        )}
        {inputType === 'select' && (
          <SelectInput
            id={inputTitle}
            options={inputOptions as InputSelectOption[]}
            onChange={(value) => handleChange(inputValue, value)}
            placeholder={placeholder}
            defaultValue={value as string}
          />
        )}
        {inputType === 'text' && (
          <YInput
            id={inputTitle}
            inputProps={{
              onChange: (e) => {
                !Number.isNaN(Number(e.target.value)) &&
                  handleChange(inputValue, e.target.value);
              },
              min: 0,
              type: 'numeric',
              placeholder: placeholder as string,
              value: (value as string) ?? '',
              pattern: '[0-9s]{13,19}',
              maxLength: 11,
            }}
          />
        )}
        {inputType === 'switch' && (
          <SwitchInputContent>
            <YSwitch
              id={inputTitle}
              isOn={value as boolean}
              onChange={(value) => handleChange(inputValue, value)}
            />
          </SwitchInputContent>
        )}
        {inputType === 'avatarSelect' && (
          <AvatarSwitch
            options={inputOptions as InputSelectOption[]}
            value={value as string}
            handleChange={(value) => handleChange(inputValue, value)}
            isDisabled={hasTexture}
          />
        )}
        {inputType === 'error' && <Alert type="error" title={inputTitle} />}
      </InputCardSection>
    </SectionCard>
  );
};
