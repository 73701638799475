import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 10px !important;

  border-radius: ${({ theme }) => theme.borderRadius.sm};

  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 20px;
    height: 20px;

    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    transition: background-color 0.2s ease, border-color 0.2s ease;

    border: 2px solid ${({ theme }) => theme.colors.border.normal[0]};

    border-radius: ${({ theme }) => theme.borderRadius.xsm};

    &.checked {
      border: none;
      background-color: ${({ theme }) => theme.colors.positive.default[0]};
    }

    &.checked::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cpath d="M7.33331 13.2541L4.41664 10.3375C4.09164 10.0125 3.57498 10.0125 3.24998 10.3375C2.92498 10.6625 2.92498 11.1791 3.24998 11.5041L6.74164 14.9958C7.06664 15.3208 7.59164 15.3208 7.91664 14.9958L16.75 6.17079C17.075 5.84579 17.075 5.32913 16.75 5.00413C16.425 4.67913 15.9083 4.67913 15.5833 5.00413L7.33331 13.2541Z" fill="%23F0FDF9"%3E%3C/path%3E%3C/svg%3E')
        no-repeat center center;
      background-size: contain;
    }

    &:disabled {
      border-color: none;
      background-color: #c0c0c0;
    }

    &:focus {
      outline: 2px solid ${({ theme }) => theme.colors.primary.default[0]};
      outline-offset: 4px;
    }
  }
`;

export const Label = styled.label`
  font: ${({ theme }) => theme.typography.label.md};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};

  & .disabled {
    color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  }
`;
