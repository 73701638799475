import { YInputSelect, YMenu, YMenuItem } from '@hand-talk/yotta-components';

export type InputSelectOption = {
  name: string;
  value: string;
};

const renderMenu = ({ options, onSelect, selectedOption, onClose }: any) => (
  <YMenu
    fluid={true}
    direction="column"
    position={{ top: '53px' }}
    zIndex={2}
    onClose={onClose}
  >
    {options.map((option: any) => (
      <YMenuItem
        key={option.value}
        onClick={() => onSelect(option)}
        selected={option.value === selectedOption?.value}
      >
        {option.name}
      </YMenuItem>
    ))}
  </YMenu>
);

interface SelectInputProps {
  id: string;
  options: InputSelectOption[];
  placeholder?: string;
  onChange: (value: string) => void;
  defaultValue?: string;
}

export const SelectInput = ({
  id,
  options,
  placeholder,
  onChange,
  defaultValue,
}: SelectInputProps) => {
  return (
    <YInputSelect
      id={id}
      placeholder={placeholder}
      renderMenu={renderMenu}
      onSelectOption={({ value }) => onChange(value)}
      options={options}
      defaultValue={defaultValue}
      scroll
    />
  );
};
