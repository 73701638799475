import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[5]};

  padding-top: ${({ theme }) => theme.spacing[3]};
`;

export const ModalFooter = styled.footer`
  display: flex;
  gap: ${({ theme }) => theme.spacing[5]};
  align-items: center;
  justify-content: end;

  padding-top: ${({ theme }) => theme.spacing[6]};
`;
