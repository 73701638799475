/** Libs */
import { useEffect, useState, useMemo } from 'react';
import i18n, { setLanguage } from '../../../infra/i18n';
import { useTranslation } from 'react-i18next';

/** Types */
import { AverageReport, ICsvLabels, Report } from '../../../infra/services/interfaces';
import { IFilterData } from '../../components/Filter/types';

/** Services */
import * as Analytics from '../../../infra/services/analytics';
import { getLastMonthPeriod } from '../../../infra/utils/utils';
import { formatPeriodWithTemplate } from '../../../infra/services/helper';

/** Components */
import LoadingWrapper from '../LoadingWrapper';
import ReportComponent from './Report';
import AverageReportComponent from './AverageReport';

/** Hooks */
import useReport from '../../hooks/useReport';
import usePreferencies from '../../hooks/usePreferencies';
import Filter from '../../components/Filter';
import useAuth from '../../hooks/useAuth';
import { exportCsv } from '../../../infra/services/exportCsv';
import { printPDF } from '../../../infra/services/helper';
import useUserInfo from '../../hooks/useUserInfo';
import useAverageReport from '../../hooks/useAverageReport';
import Footer from '../../components/Footer';

/** Context */
import { useReportContext } from '../../providers/ReportContext';

const ClientDashboard = () => {
  const { setReport: setContextReport } = useReportContext();
  const { language, accessKey } = usePreferencies();
  const { authState } = useAuth();
  const { fetchUserInfo, userInfoState } = useUserInfo();
  const [showAverageReport, setShowAverageReport] = useState(false);
  const { report, filterOptions, isLoading, getReport, filterReport } = useReport();
  const {
    averageReport,
    filterAverageReport,
    isLoading: isLoadingAverageReport,
  } = useAverageReport();
  const [cachedFilterData, setCachedFilterData] = useState<IFilterData | null>(null);

  useEffect(() => {
    if (authState.type === 'success' && userInfoState.type === 'none') {
      fetchUserInfo();
    }
  }, [authState, fetchUserInfo, userInfoState]);

  useEffect(() => {
    if (!report) return;
    Analytics.accessCounterEvent(report.info.domain);
    /** Atualiza o contexto com o report */
    setContextReport(report);
  }, [report]);

  useEffect(() => {
    if (!accessKey || !language) return;

    showAverageReport
      ? filterAverageReport(accessKey, getLastMonthPeriod(), language)
      : getReport(accessKey, language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessKey, language]);

  const handleOnFilter = (data: IFilterData) => {
    setCachedFilterData(data);
    setLanguage(data.language);

    data.view === 'monthlyAverage'
      ? filterAverageReport(accessKey, data.period, data.language)
      : filterReport(accessKey, data.period, data.language);

    setShowAverageReport(data.view === 'monthlyAverage');
  };

  const initialData = useMemo(() => {
    return {
      period: report?.period || getLastMonthPeriod(),
      language,
      view: 'standard',
    } as IFilterData;
  }, [language, report]);

  const filter = (tagReport: string, periodReport: string) => {
    return (
      <Filter
        onFilter={handleOnFilter}
        initialData={cachedFilterData || initialData}
        options={filterOptions}
        periodReport={periodReport}
        tagReport={tagReport}
      />
    );
  };

  const { t: translateReport } = useTranslation('Report');

  const csvLabels: ICsvLabels = {
    csvTitle: translateReport('csvTitle'),
    csvDiary: translateReport('csvDiary'),
    csvCumulative: translateReport('csvCumulative'),
    csvTopPages: translateReport('csvTopPages'),
    csvTopStates: translateReport('csvTopStates'),
    csvTopDevices: translateReport('csvTopDevices'),
    csvTopAddons: translateReport('csvTopAddons'),
  };

  const handleExportCSV = () => {
    if (!report) return;

    exportCsv(report, csvLabels);
  };

  const handleExportPDF = () => {
    printPDF();
  };

  const createReport = (report: Report) => {
    const { period } = report;
    const periodString = formatPeriodWithTemplate(period, i18n.language);
    return (
      <>
        <ReportComponent
          translationsAccumulatedFormatted=""
          userInfoState={userInfoState}
          isAverage={showAverageReport}
          report={report}
          filter={filter('standard', periodString)}
          handleExportCSV={handleExportCSV}
          handleExportPDF={handleExportPDF}
        />
      </>
    );
  };

  const createAverageReport = (averageReport: AverageReport) => {
    const { period } = averageReport;
    const periodString = formatPeriodWithTemplate(period, i18n.language);
    return (
      <>
        <AverageReportComponent
          isAverage={showAverageReport}
          averageReport={averageReport}
          filter={filter('monthlyAverage', periodString)}
          handleExportPDF={handleExportPDF}
          userInfoState={userInfoState}
        />
      </>
    );
  };

  return (
    <LoadingWrapper loading={isLoading || isLoadingAverageReport}>
      {!isLoading && !showAverageReport && report && createReport(report)}
      {!isLoadingAverageReport &&
        showAverageReport &&
        averageReport &&
        createAverageReport(averageReport)}
      <Footer />
    </LoadingWrapper>
  );
};

export default ClientDashboard;
