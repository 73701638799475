import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;

  background-color: ${({ theme }) => theme.colors.surface.dim[1][0]};

  position: fixed;
  bottom: 0;

  display: flex;
  gap: ${({ theme }) => theme.spacing[6]};
  align-items: center;
  justify-content: center;

  padding: ${({ theme }) => theme.spacing[5]};

  box-shadow: ${({ theme }) => theme.shadow[3]};
`;
