/** Libs */
import { createGlobalStyle } from 'styled-components';

/** Services */
import {
  mobileMediaQuery,
  printMediaQuery,
  tabletMediaQuery,
} from '../../../infra/services/helper';

/** Tokens */
import { font } from '../tokens/fonts';
import { brand, feedback, highlight, neutral } from '../tokens/colors';
import { spacing } from '../tokens/spacings';

export const GlobalStyle = createGlobalStyle`
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html,
body {
  width: 100%;
  height: 100%;

  * {
    ${({ theme }) =>
      printMediaQuery(`
      box-shadow: none !important;
      background-color: ${theme.colors.surface.dim[1][0]} !important;
    `)}
 }
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  border: none;
}

body {
  background: ${neutral.color.high.main};
  background-color: ${({ theme }) => theme.colors.surface.dim.background[0]};
  font: ${font.paragraph.medium};
  font-family: ${font.family};
  color: ${neutral.color.low.main}; 
}

.TitleWithIcon {
  font: ${font.heading.medium};
  font-family: ${font.family};
  color: ${neutral.color.low.main};
  margin-left: ${spacing.nano};
}

.RainbowLoader {
  height: 2px;
  background: linear-gradient(
    to right,
    ${feedback.color.sucess.main} 20%,
    ${brand.color.main} 20% 40%,
    ${feedback.color.alert.main} 40% 60%,
    ${feedback.color.info.main} 60% 80%,
    ${highlight.color.light} 80%
  );
}

.FlexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AlignCenter {
  display: flex;
  align-items: center;
}

.SpaceBetweenDesktop {
  display: flex;
  justify-content: space-between;

   ${mobileMediaQuery(`
    flex-direction: column;
  `)}
}

.FlexCenterColumnTablet {
  display: flex;
  align-items: center;
  justify-content: center;

  ${tabletMediaQuery(`
    flex-direction: column;
  `)}
}

.TitleSM {
  font: ${({ theme }) => theme.typography.title.sm};
  color: ${({ theme }) => theme.colors.surface.onSurfaceLow[0]};
  font-weight: 800;
}

.TitleAll {
  margin-top: 50px;
  font: ${({ theme }) => theme.typography.title.lg};
  color:${({ theme }) => theme.colors.surface.onSurface[0]};
}

.PeriodText {
  margin: 20px 0;
  font: ${font.subtitle.small};
  font-family: ${font.family};
}

.PeriodTextCustom {
  font: ${font.subtitle.Xsmall};
  font-family: ${font.family};
  color: ${brand.color.dark};
}

/* React Select */

.css-1fdsijx-ValueContainer {
  padding: 0 !important;
}

/* React Select */

.NotPrint {
  ${printMediaQuery(`
    visibility: hidden;
    display: none;
  `)}
}

.OnlyPrint {
  display: none;

  ${printMediaQuery(`
    display: block;
    padding-top: 15px;
 `)}
}

.PrintOnly {
  ${printMediaQuery(`
    margin-top: 600px;  
  `)}
  }

.PrintOnlyMedium {
  ${printMediaQuery(`
    margin-top: 450px;  
 `)}
  }

.PrintOnlySmall {
  ${printMediaQuery(`
    margin-top: 350px;  
 `)}
  }


.PrintRemoveMargin {
  ${printMediaQuery(`
    margin-top: 0px;
 `)}
  }

.recharts-wrapper,
    .recharts-surface {
      ${printMediaQuery(`
        width: calc(100%)!important;
      `)}
    }
`;
