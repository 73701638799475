import { useTranslation } from 'react-i18next';
import { enUS, ptBR } from 'date-fns/locale';

/**
 * Hook que retorna o objeto de localização (locale) baseado no idioma atual do i18n.
 *
 * @returns {Locale} Objeto de localização correspondente ao idioma atual ou `ptBR` como padrão.
 */
const useLocale = (): Locale => {
  const { i18n } = useTranslation();

  const locales: Record<string, Locale> = {
    en: enUS,
    pt: ptBR,
  };

  return locales[i18n.language] || ptBR;
};

export default useLocale;
