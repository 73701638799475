/** Libs */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

/** Types */
import { Language } from '../services/interfaces';

/** Utils */
import { getOralLanguage } from '../utils/utils';

/** Translations */
import translationsReport from '../../view/pages/ClientDashboard/ClientDashboard.json';
import translationsTopAddons from '../../view/components/TopAddons/TopAddons.json';
import translationsLanguageSwitcher from '../../view/components/LanguageSwitcher/LanguageSwitcher.json';
import translationsHeader from '../../view/components/Header/Header.json';
import translationsHeaderForm from '../../view/components/HeaderForm/HeaderForm.json';
import translationFeedbackCard from '../../view/components/FeedbackCard/FeedbackCard.json';
import translationFilter from '../../view/components/Filter/Filter.json';
import translationsTopContainer from '../../view/components/TopContainer/TopContainer.json';
import translationsOverview from '../../view/components/Overview/Overview.json';
import translationChart from '../../view/components/Chart/Chart.json';
import translationChartUsers from '../../view/components/ChartUsers/ChartUsers.json';
import translationsUserTable from '../../view/components/UserTable/UserTable.json';
import translationsAchievement from '../../view/components/Achievement/Achievement.json';
import translationsFooter from '../../view/components/Footer/Footer.json';
import translationsInvalidToken from '../../view/components/InvalidToken/InvalidToken.json';
import translationsDataSection from '../../view/components/DataSection/DataSection.json';
import translationsMetadata from './Metadata.json';
import authForms from '../../view/components/AuthForms/AuthForms.json';
import menu from '../../view/components/Menu/Menu.json';
import translationsYCalendar from '../../view/components/YCalendar/YCalendar.json';
import table from '../../view/components/Table/Table.json';
import translationsSubheader from '../../view/components/SubHeader/SubHeader.json';
import translationsLoadingAnimated from '../../view/components/LoadingAnimated/LoadingAnimated.json';
import translationsRemoteConfig from '../../view/pages/RemoteConfig/RemoteConfig.json';
import translationsCollapseCard from '../../view/components/CollapseCard/CollapseCard.json';
import translationsTimeline from '../../view/components/Timeline/Timeline.json';
import translationsAlertComponent from '../../view/components/Alert/Alert.json';
import translateSlidePage from '../../view/pages/Retrospective/components/RetrospectiveSlidePage/SlidePage.json';
import translateWelcomeSlidePage from '../../view/pages/Retrospective/pages/welcomeSlide/WelcomeSlide.json';
import translateWordsSlidePage from '../../view/pages/Retrospective/pages/wordsSlide/WordsSlide.json';
import translateUsersSlidePage from '../../view/pages/Retrospective/pages/usersSlide/UsersSlide.json';
import translatePagesSlidePage from '../../view/pages/Retrospective/pages/pagesSlide/PagesSlide.json';
import translateAddonsSlidePage from '../../view/pages/Retrospective/pages/topAddonsSlide/TopAddons.json';
import translateShareSlidePage from '../../view/pages/Retrospective/pages/shareResultSlide/ShareResult.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      SubHeader: translationsSubheader.en,
      Report: translationsReport.en,
      TopAddons: translationsTopAddons.en,
      LanguageSwitcher: translationsLanguageSwitcher.en,
      Header: translationsHeader.en,
      HeaderForm: translationsHeaderForm.en,
      FeedbackCard: translationFeedbackCard.en,
      Filter: translationFilter.en,
      TopContainer: translationsTopContainer.en,
      UserTable: translationsUserTable.en,
      Chart: translationChart.en,
      ChartUsers: translationChartUsers.en,
      Overview: translationsOverview.en,
      Achievement: translationsAchievement.en,
      Footer: translationsFooter.en,
      LoadingAnimated: translationsLoadingAnimated.en,
      InvalidToken: translationsInvalidToken.en,
      DataSection: translationsDataSection.en,
      Metadata: translationsMetadata.en,
      AuthForms: authForms.en,
      Menu: menu.en,
      YCalendar: translationsYCalendar.en,
      Table: table.en,
      RemoteConfig: translationsRemoteConfig.en,
      CollpseCard: translationsCollapseCard.en,
      Timeline: translationsTimeline.en,
      AlertComponent: translationsAlertComponent.en,
      SlidePage: translateSlidePage.en,
      WelcomeSlide: translateWelcomeSlidePage.en,
      WordsSlide: translateWordsSlidePage.en,
      UsersSlide: translateUsersSlidePage.en,
      PagesSlide: translatePagesSlidePage.en,
      TopAddonsSlide: translateAddonsSlidePage.en,
      ShareSlidePage: translateShareSlidePage.en,
    },
    pt: {
      SubHeader: translationsSubheader.pt,
      Report: translationsReport.pt,
      TopAddons: translationsTopAddons.pt,
      LanguageSwitcher: translationsLanguageSwitcher.pt,
      Header: translationsHeader.pt,
      HeaderForm: translationsHeaderForm.pt,
      FeedbackCard: translationFeedbackCard.pt,
      Filter: translationFilter.pt,
      TopContainer: translationsTopContainer.pt,
      UserTable: translationsUserTable.pt,
      Overview: translationsOverview.pt,
      Chart: translationChart.pt,
      ChartUsers: translationChartUsers.pt,
      Achievement: translationsAchievement.pt,
      Footer: translationsFooter.pt,
      LoadingAnimated: translationsLoadingAnimated.pt,
      InvalidToken: translationsInvalidToken.pt,
      DataSection: translationsDataSection.pt,
      Metadata: translationsMetadata.pt,
      AuthForms: authForms.pt,
      Menu: menu.pt,
      YCalendar: translationsYCalendar.pt,
      Table: table.pt,
      RemoteConfig: translationsRemoteConfig.pt,
      CollpseCard: translationsCollapseCard.pt,
      Timeline: translationsTimeline.pt,
      AlertComponent: translationsAlertComponent.pt,
      SlidePage: translateSlidePage.pt,
      WelcomeSlide: translateWelcomeSlidePage.pt,
      WordsSlide: translateWordsSlidePage.pt,
      UsersSlide: translateUsersSlidePage.pt,
      PagesSlide: translatePagesSlidePage.pt,
      TopAddonsSlide: translateAddonsSlidePage.pt,
      ShareSlidePage: translateShareSlidePage.pt,
    },
  },
  lng: 'pt',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export const setLanguage = async (language: Language) => {
  const oralLanguage = getOralLanguage(language);
  await i18n.changeLanguage(oralLanguage);

  document.title = i18n.t('title', { ns: 'Metadata' });
};

export default i18n;
