import { useEffect, useState } from 'react';
import { CheckboxContainer, Container, Label } from './styles';

interface CheckboxProps {
  name: string;
  onChange: (value: boolean) => void;
  label: string;
  value: boolean;
}

export const Checkbox = ({ name, label, onChange, value }: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleChangeChecked = () => {
    onChange(!isChecked);
  };

  return (
    <Container>
      <CheckboxContainer>
        <input
          id={name}
          type="checkbox"
          className={isChecked ? 'checked' : ''}
          onChange={(e) => handleChangeChecked()}
          checked={isChecked}
        />
      </CheckboxContainer>
      <Label>{label}</Label>
    </Container>
  );
};
