import { CollapseCard } from '../../../../components/CollapseCard';
import { RemoteConfigCardTypes } from '../../constructor/types';

interface RemoteConfigCardProps {
  cardType?: RemoteConfigCardTypes;
  title: string;
  children: JSX.Element;
}

export const RemoteConfigCard = ({
  cardType,
  children,
  title,
}: RemoteConfigCardProps) => (
  <>
    {cardType === 'collapse' ? (
      <CollapseCard title={title} noBorder>
        {children}
      </CollapseCard>
    ) : (
      <>{children}</>
    )}
  </>
);
