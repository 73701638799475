import { RemoteConfigSchema } from '../../../../../domain/schemas/remoteConfig/RemoteConfigSchema';

export const previewIframeContent = (
  isMobile: boolean = false,
  currentConfig: RemoteConfigSchema
) => {
  const betaUrl = window.location.href.includes('client-dashboard-beta.web.app');

  return `
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="styles.css" />
        <title>Preview</title>
      </head>
      <body id="config_preview_${isMobile ? 'mobile' : 'desktop'}">
        <script src="https://plugin.handtalk.me/web/latest/handtalk.min.js"></script>
        <script>
          var ht = new HT(
            ${JSON.stringify({
              token: betaUrl
                ? 'df03afabdc98e53eef377512ed669a7b'
                : '894ad581fb6306c7363cb9c32a5dd9dd',
              ...currentConfig.config,
              isMajority: true,
            })}
          )
        </script>

        <div class="card1"></div>
        <div class="card2"></div>
        ${
          isMobile
            ? ''
            : `
            <div class="card3"></div>
            <div class="card4"></div>
            <div class="card5"></div>
          `
        }
      </body>
    </html>
  `;
};

export const PreviewIframeStyles = `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    width: 100vw;
    height: 100vh;

    background-color: #fff;

    padding: 8px;
    padding-bottom: 0;

    display: grid;
    gap: 8px;
  }

  #config_preview_desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 0.3fr 1fr;
    grid-template-areas:
      'card1 card1 card1 card1 card1'
      'card1 card1 card1 card1 card1'
      'card1 card1 card1 card1 card1'
      'card2 card2 card2 card3 card4'
      'card2 card2 card2 card5 card5';
  }

  #config_preview_mobile {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 0.8fr 1fr;

    grid-template-areas:
      'card1'
      'card1'
      'card2';
  }

  .card1,
  .card2,
  .card3,
  .card4,
  .card5 {
    background-color: #d5dce3;
    border-radius: 8px;
  }

  .card1 {
    grid-area: card1;
  }

  .card2 {
    grid-area: card2;
  }

  .card3 {
    grid-area: card3;
  }

  .card4 {
    grid-area: card4;
  }

  .card5 {
    grid-area: card5;
  }
`;
