import { YSwitch } from '@hand-talk/yotta-components';
import { HeaderInputOption } from '../../constructor/types';
import { CardHeader, HeaderInputContent } from './styles';

interface CustomCardHeaderProps {
  title: string;
  description?: string;
  headerInput?: HeaderInputOption;
  handleActiveHeader: (name: string, value: boolean) => void;
  value: boolean;
}

export const CustomCardHeader = ({
  title,
  description,
  headerInput,
  handleActiveHeader,
  value,
}: CustomCardHeaderProps) => {
  return (
    <CardHeader>
      <div>
        <h2>{title}</h2>
        <HeaderInputContent>
          {headerInput ? (
            <YSwitch
              aria-label="Este switch está no estado ligado"
              isOn={value}
              onChange={(value: boolean) =>
                handleActiveHeader(headerInput.inputId, value)
              }
            />
          ) : (
            <></>
          )}
        </HeaderInputContent>
      </div>
      {description ? <p>{description}</p> : <></>}
    </CardHeader>
  );
};
