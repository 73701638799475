import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[3]};
`;

export const AvatarContent = styled.button<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  width: 107px;
  height: 52px;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[3]};

  padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[3]}`} !important;
  border: 1px solid transparent;

  border-color: ${({ theme, isActive }) =>
    isActive ? `${theme.colors.primary.default[0]}` : 'transparent'};
  border-radius: 80px;

  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: .4;
    pointer-events: none;
  `};

  &:hover,
  &:focus-visible,
  &:focus {
    outline: none;
    border: ${({ theme }) => `1px solid ${theme.colors.primary.default[0]}`};

    p {
      color: ${({ theme }) => theme.colors.primary.default[0]};
    }
  }

  &:focus-visible,
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.primary.default[0]};
    outline-offset: -2px;
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary.default[0] : theme.colors.border.low[0]};
  }

  p {
    font-size: ${({ theme }) => theme.typography.paragraph.md};
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary.default[0] : theme.colors.surface.onSurface[0]};
  }
`;

export const AvatarImage = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 36px;
    width: 36px;

    border-radius: 36px;
    background-color: #b0beca;
  }
`;
