import { useTranslation } from 'react-i18next';
import YModal from '../../../../components/YModal';
import { ModalContent, ModalFooter } from './styles';
import { YButton } from '@hand-talk/yotta-components';

interface ConfigModalProps {
  title: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  hasFooter: boolean;
  textContent: JSX.Element;
  buttonLeftLabel?: string;
  buttonRightLabel?: string;
  handleClickLeft?: () => void;
  handleClickRight?: () => void;
}

export const ConfigModal = ({
  title,
  isModalOpen,
  handleCloseModal,
  hasFooter,
  textContent,
  buttonLeftLabel,
  buttonRightLabel,
  handleClickLeft,
  handleClickRight,
}: ConfigModalProps) => {
  const { t: translate } = useTranslation('RemoteConfig');

  return (
    <YModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      title={title}
      closeButtonAriaLabel={translate('closeModal')}
      size="sm"
    >
      <ModalContent>{textContent}</ModalContent>
      {hasFooter && (
        <ModalFooter>
          <YButton
            iconPosition="left"
            size="fitContent"
            variation="text"
            onClick={() => handleClickLeft!()}
          >
            {buttonLeftLabel}
          </YButton>
          <YButton
            iconPosition="left"
            size="fitContent"
            variation="fill"
            onClick={() => handleClickRight!()}
          >
            {buttonRightLabel}
          </YButton>
        </ModalFooter>
      )}
    </YModal>
  );
};
