import React from 'react';

/** Services */
import { makeCalendar } from '../utils/makeCalendar';
import { getFormattedMonth, getFormattedYear } from '../../../../infra/utils/dateUtils';
import { Timestamp } from '../../../../infra/services/Timestamp';

/** Hooks */
import useLocale from '../../../hooks/useLocale';

/** Styles */
import { Container } from './styles';

/** Components */
import CalendarHeader from '../CalendarHeader';
import CalendarWeekDays from '../CalendarWeekDays';

export interface CalendarDayData {
  isSameMonth: boolean;
  timestamp: Timestamp;
  displayDay: string;
}

interface ICalendarContainer {
  monthTimestamp: Timestamp;
  onPrevMonth?: () => void;
  onNextMonth?: () => void;
  children: (day: CalendarDayData) => React.ReactNode;
}

const CalendarContainer: React.FC<ICalendarContainer> = ({
  children,
  monthTimestamp,
  onPrevMonth,
  onNextMonth,
}) => {
  const locale = useLocale();

  /** Gera o calendário com base no mês atual */
  const calendar = React.useMemo(() => makeCalendar(monthTimestamp), [monthTimestamp]);

  /** Obtém o nome do mês e o ano formatados */
  const month = getFormattedMonth(monthTimestamp, locale);
  const year = getFormattedYear(monthTimestamp, locale);

  return (
    <Container>
      <CalendarHeader
        onPrev={onPrevMonth}
        onNext={onNextMonth}
        content={
          <>
            {month} {year}
          </>
        }
      />
      <table>
        <thead>
          <CalendarWeekDays />
        </thead>
        <tbody>
          {calendar.map((week, index) => (
            <tr key={String(index)}>
              {week.map((day) => (
                <td key={day.timestamp}>{children(day)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default CalendarContainer;
