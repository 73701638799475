/**  Libs */
import styled from 'styled-components';

/** Tokens */
import { feedback, neutral } from '../../assets/tokens/colors';
import { font } from '../../assets/tokens/fonts';
import { border } from '../../assets/tokens/borders';

/** Services */
import {
  getIconStyle,
  mobileMediaQuery,
  tabletMediaQuery,
} from '../../../infra/services/helper';

/** Interfaces */
import { IFeedbackCard } from '../../../infra/services/interfaces';

/** Altera cor do FeedbackCard */
const feedbackColor = (props: IFeedbackCard) => {
  return props.type === 'positive'
    ? feedback.color.sucess.main
    : props.type === 'info'
    ? feedback.color.info.main
    : props.type === 'alert'
    ? feedback.color.alert.dark
    : feedback.color.error.dark;
};

export const SectionFeedback = styled.section`
  display: flex;
  justify-content: space-between;
  background: ${neutral.color.high.lightest};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: ${border.radius.md};
  margin: 37px auto;
  padding: 0px 0px 20px 16px;
  box-sizing: border-box;
  border-top: 16px solid;
  border-color: ${feedbackColor};
  max-width: 650px;
  width: 100%;

  ${tabletMediaQuery(`
    margin: 24px  auto;
  `)}

  ${mobileMediaQuery(`
    margin: 32px auto;
  `)}
`;

export const StyledIcon = styled.span`
  ${(props: IFeedbackCard) => getIconStyle('44px', feedbackColor(props))};
  align-self: center;
  margin: 0 19px 0 8px;

  @media screen and (max-width: 599px) {
    margin: 25px 0 25px 25px;
  }
`;

export const TitleContent = styled.div`
  padding-top: 20px;

  @media screen and (max-width: 599px) {
    margin-left: 9px;
    padding-top: 0px;
  }
`;

export const Title = styled.h2`
  font: ${font.heading.medium};
  font-family: ${font.family};
  text-transform: uppercase;
`;

export const Paragraph = styled.p`
  margin-top: 5px;
  font-family: ${font.family};

  .dark-text {
    font-weight: bold;
  }
`;

export const ButtonLink = styled.button`
  font-weight: bold;
  color: ${feedback.color.info.main};
  cursor: pointer;
  background-color: transparent;
`;

export const MobileContent = styled.div`
  display: flex;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;
