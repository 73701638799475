import styled from 'styled-components';

export const CardHeader = styled.header`
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font: ${({ theme }) => theme.typography.title.md} !important;
      color: ${({ theme }) => theme.colors.surface.onSurface[0]};
    }
  }

  p {
    padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[5]}`};
    padding-top: 0;

    font: ${({ theme }) => theme.typography.paragraph.md};
    color: ${({ theme }) => theme.colors.surface.onSurfaceLow[0]};
  }
`;

export const HeaderInputContent = styled.div`
  padding: ${({ theme }) => theme.spacing[5]} !important;

  label span {
    width: 52px;
    height: 32px;
  }
`;
