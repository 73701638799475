import styled from 'styled-components';
import { mobileMediaQuery, tabletMediaQuery } from '../../../../../infra/services/helper';

export const PreviewCardContainer = styled.section`
  width: 100%;

  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
  flex-direction: column;

  padding-bottom: 120px;
`;

export const PreviewHeader = styled.header`
  width: fit-content;
  margin-top: ${({ theme }) => theme.spacing[6]};
`;

export const PreviewContent = styled.div<{ isMobile: boolean }>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    max-width: ${({ isMobile }) => (isMobile ? '420px' : '900px')};
    height: 600px;

    border-radius: ${({ theme }) => theme.borderRadius.md};

    ${tabletMediaQuery(`
      width: 520px;
      height: 450px;
    `)};

    ${mobileMediaQuery(`
      height: 550px;
    `)};
  }
`;

export const PreviewIframe = styled.div``;
