import styled from 'styled-components';

interface ICollapseContainer {
  isOpen: boolean;
  isDisabled?: boolean;
  noBorder?: boolean;
}

export const CollapseContainer = styled.div<ICollapseContainer>`
  border: ${({ theme, noBorder }) =>
    noBorder ? 'none' : `1px solid ${theme.colors.border.low[0]}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};

  display: flex;
  flex-direction: column;

  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

export const CollapseHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[5]}`} !important;

  h3 {
    font: ${({ theme }) => theme.typography.title.sm};
    color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  }
`;

export const CollapseHeaderButton = styled.button<{ isDisabled?: boolean }>`
  width: 32px;
  height: 32px;

  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};

  &:focus-visible,
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.primary.default[0]};
    outline-offset: -2px;
    ${({ theme }) => theme.colors.primary.default[0]};
  }

  img {
    width: 32px;
    height: 32px;
  }
`;
