import { useTranslation } from 'react-i18next';
import { ConfigModal } from '.';

interface PublishModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleSaveConfig: () => void;
}

export const PublishModal = ({
  isModalOpen,
  handleCloseModal,
  handleSaveConfig,
}: PublishModalProps) => {
  const { t: translate } = useTranslation('RemoteConfig');

  return (
    <ConfigModal
      {...{
        isModalOpen,
        hasFooter: true,
        buttonLeftLabel: translate('publishAlertModalLeftBtn'),
        buttonRightLabel: translate('publishAlertModalRightBtn'),
        handleCloseModal,
        handleClickLeft: handleCloseModal,
        handleClickRight: () => handleSaveConfig(),
      }}
      title={translate('publishAlertModalTitle')}
      textContent={
        <>
          <p>{translate('firstPublishAlertModal')}</p>
          <p>{translate('secondPublishAlertModal')}</p>
        </>
      }
    />
  );
};
