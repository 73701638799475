import styled from 'styled-components';

export const AlertContainer = styled.div<{
  type: 'error' | 'info' | 'success';
}>`
  padding: ${({ theme, type }) =>
    type === 'info'
      ? `${theme.spacing[2]} ${theme.spacing[3]}`
      : theme.spacing[4]} !important;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[3]};

  border-radius: ${({ theme, type }) =>
    type === 'success' ? theme.borderRadius.md : theme.borderRadius.sm};
  border: 1px solid
    ${({ theme, type }) =>
      type === 'error'
        ? theme.colors.error.default[0]
        : type === 'success'
        ? theme.colors.positive.default[0]
        : 'transparent'};

  background-color: ${({ theme, type }) =>
    type === 'error'
      ? theme.colors.error.container[0]
      : type === 'success'
      ? theme.colors.positive.container[0]
      : theme.colors.tertiary.onLow[0]};

  p {
    color: ${({ theme, type }) =>
      type === 'error'
        ? theme.colors.error.onContainer[0]
        : theme.colors.tertiary.onContainer[0]};
  }
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: ${({ theme }) => theme.spacing[6]};

  cursor: pointer;
`;
