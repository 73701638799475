import { useEffect, useState } from 'react';
import { InputSelectOption } from '../Select';
import hugoImg from '../../assets/svg/pose_hugo.svg';
import mayaImg from '../../assets/svg/pose_maya.svg';

import { AvatarContent, AvatarImage, Container } from './styles';

interface AvatarProps {
  name: string;
  handleClick: () => void;
  isActive: boolean;
  isDisabled?: boolean;
}

const Avatar = ({ name, handleClick, isActive, isDisabled }: AvatarProps) => {
  const imgContent = name.toLocaleLowerCase() === 'hugo' ? hugoImg : mayaImg;

  return (
    <AvatarContent
      onClick={() => !isDisabled && handleClick()}
      isActive={isActive}
      isDisabled={isDisabled}
    >
      <AvatarImage>
        <img src={imgContent} alt={`Avatar ${name}`} />
      </AvatarImage>
      <p>{name}</p>
    </AvatarContent>
  );
};

interface AvatarSwitchProps {
  options: InputSelectOption[];
  handleChange: (value: string) => void;
  value: string;
  isDisabled?: boolean;
}

export const AvatarSwitch = ({
  options,
  value,
  handleChange,
  isDisabled,
}: AvatarSwitchProps) => {
  const [selectedAvatar, setSelectedAvatar] = useState<string | undefined>(
    options[0].value
  );

  useEffect(() => {
    setSelectedAvatar(value);
  }, [value]);

  return (
    <Container>
      {options.map((option) => (
        <Avatar
          key={option.name}
          name={option.name}
          handleClick={() => handleChange(option.value)}
          isActive={selectedAvatar === option.value}
          isDisabled={isDisabled}
        />
      ))}
    </Container>
  );
};
