import { useNavigate, useParams } from 'react-router-dom';

const useTabNavigation = () => {
  const paramns = useParams<{ accessKey: string }>();
  const navigate = useNavigate();

  const handleChangeTab = (route: string) => {
    const updatedRoute = route.replace(':accessKey', paramns.accessKey ?? '');
    navigate(updatedRoute);
  };

  return { handleChangeTab };
};

export default useTabNavigation;
