import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import Menu from '../../components/Menu';
import useAuth from '../../hooks/useAuth';
import useUserInfo from '../../hooks/useUserInfo';
import { IPrimaryLayoutRoute } from '../../routes';
import { Main } from './styles';

// Services
import * as Analytics from '../../../infra/services/analytics';
import { SubHeader } from '../../components/SubHeader';

interface IPrimaryLayout extends Partial<IPrimaryLayoutRoute> {
  children: JSX.Element;
}

export const PrimaryLayout: React.FC<IPrimaryLayout> = ({ children, ...props }) => {
  const paramns = useParams<{ accessKey: string }>();
  const { signOut, authState } = useAuth();
  const [hasTabsOptions, setHasTabsOptions] = useState<boolean>(false);
  
  const { userInfoState, fetchUserInfo } = useUserInfo();

  useEffect(() => {
    fetchUserInfo();
  }, [])

  useEffect(() => {
    const checkCurrentReport = (reportKeyList: string[]) => {
      if(reportKeyList.includes(paramns.accessKey as string)) {
        setHasTabsOptions(true);
      }
    }

    if(userInfoState.type === "success") {
      const reports = userInfoState.userInfo?.reports || [];
      const reportKeysList = reports.map(report => report.accessKey.value);
      checkCurrentReport(reportKeysList);
    }
  }, [userInfoState]);

  

  const handleLogout = async () => {
    await signOut();
    await Analytics.logoutEvent();
    window.location.href = '/';
  };

  const header = (
    <>
      <Header.Navbar>
        <LanguageSwitcher />
        {authState.type === 'success' && <Menu.Profile onClickLogout={handleLogout} />}
      </Header.Navbar>
      {!props.onlyHeader && (
        <Header.Menu>
          <Header.Containers.Domain>
            <SubHeader
              authStateType={authState.type}
              hasTabsOptions={hasTabsOptions}
            />
          </Header.Containers.Domain>
        </Header.Menu>
      )}
    </>
  );

  return (
    <Main>
      {header}
      {children}
    </Main>
  );
};
