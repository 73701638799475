import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import { YIcon, YIconButton, YTabPane, YTabs } from '@hand-talk/yotta-components';

// Services
import * as Analytics from '../../../infra/services/analytics';

// Styles
import { SubheaderContainer } from './styles';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isMobile } from '../../../infra/services/helper';

type StateType = 'success' | 'error' | 'none' | 'loading';

interface SubHeaderProps {
  authStateType: StateType;
  hasTabsOptions?: boolean;
}

type PageOption = {
  label: string;
  route: string;
  icon: JSX.Element;
  isPrivate: boolean;
};

const pageOptions: PageOption[] = [
  {
    label: 'PrimaryTab',
    route: '/:accessKey',
    icon: <YIcon name="externalLink" />,
    isPrivate: false,
  },
  {
    label: 'SecondaryTab',
    route: '/remote-config/:accessKey',
    icon: <YIcon name="externalLink" />,
    isPrivate: true,
  },
];

export const SubHeader = ({ authStateType, hasTabsOptions = false }: SubHeaderProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const paramns = useParams();
  const { t: translateSubHeader } = useTranslation('SubHeader');
  const { t: translateReport } = useTranslation('Report');
  const [selectedTab, setSelectedTab] = useState(pageOptions[0].route);
  const [hasTabs, setHasTabs] = useState(true);

  useEffect(() => {
    const step = searchParams.get('step');

    if (step && ['preview'].includes(step)) {
      setHasTabs(false);
    } else {
      setHasTabs(hasTabsOptions)
    }

    return () => setHasTabs(false);
  }, [searchParams, hasTabsOptions]);

  const getActiveTab = (pathname: string) => {
    let currentRoute = pathname.split('?')[0];

    currentRoute = currentRoute.replace(paramns.accessKey as string, ':accessKey');

    return currentRoute;
  };

  useEffect(() => {
    const activeTab = getActiveTab(location.pathname);
    setSelectedTab(activeTab);
  }, [location.pathname]);

  const handleGoToDomainList = async () => {
    await Analytics.clickBackToDomainListsEvent();
    navigate('/domain-list');
  };

  const handleChangeTab = (route: string) => {
    navigate(route.replace(':accessKey', paramns.accessKey ?? ''));
  };

  return (
    <SubheaderContainer>
      <Header.Containers.Upper>
        <Layout.Flex gap={8}>
          {authStateType === 'success' && (
            <YIconButton
              aria-label={translateReport('buttonListDomain')}
              title={translateReport('buttonListDomain')}
              onClick={handleGoToDomainList}
              children={<YIcon name="arrowLeft" />}
            />
          )}
          <Header.Title label={translateSubHeader('Title')} />
        </Layout.Flex>
      </Header.Containers.Upper>
      {authStateType === 'success' && hasTabs && (
        <Header.Containers.Lower>
          <YTabs
            onTabChange={() => {}}
            activeTabId={selectedTab}
            hasBorder={false}
            fullSize={isMobile()}
          >
            {Array.isArray(pageOptions) &&
              pageOptions.map(({ route, label, isPrivate }) =>
                !isPrivate || (isPrivate && authStateType === 'success') ? (
                  <YTabPane
                    key={label}
                    id={route}
                    label={translateSubHeader(label)}
                    aria-label={`${translateSubHeader(`${label}AriaLabel`)}`}
                    title={translateSubHeader(label)}
                    onClick={() => handleChangeTab(route)}
                  >
                    <></>
                  </YTabPane>
                ) : (
                  <div key={label}></div>
                )
              )}
          </YTabs>
        </Header.Containers.Lower>
      )}
    </SubheaderContainer>
  );
};
