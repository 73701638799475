/** Libs */
import React from 'react';

import { format, addDays, startOfWeek } from 'date-fns';

/** Hooks */
import useLocale from '../../../hooks/useLocale';

/** Styles */
import { TableData } from './styles';

const CalendarWeekDays: React.FC = () => {
  const locale = useLocale();

  /** Obtém o primeiro dia da semana atual (domingo) */
  const weekStart = startOfWeek(new Date(), { locale });

  /** Gera uma lista com os nomes abreviados dos dias da semana */
  const days = Array.from({ length: 7 }, (_, i) => {
    const day = format(addDays(weekStart, i), 'EEE', { locale });
    return day.charAt(0).toUpperCase() + day.slice(1, 3);
  });

  return (
    <tr>
      {days.map((day) => (
        <TableData key={day} abbr={day}>
          {day}
        </TableData>
      ))}
    </tr>
  );
};

export default CalendarWeekDays;
