import styled, { css, DefaultTheme } from 'styled-components';

interface IButton {
  isSelected: boolean;
  isInRange: boolean;
  isStart: boolean;
  isEnd: boolean;
  disabled?: boolean;
}

export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 16px 16px 0;
  pointer-events: none;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: ${({ theme }) => theme.spacing[3]} 16px ${({ theme }) => theme.spacing[5]};
  width: 100%;
`;

/** Botões padrão  */
const getDefaultStyles = (theme: DefaultTheme, disabled: boolean) => css`
  background-color: ${theme.colors.surface.bright.background[0]};
  border-radius: ${theme.borderRadius.full};
  color: ${disabled
    ? theme.colors.primary.on[theme.states.disabled]
    : theme.colors.surface.onSurface[0]};

  &:hover {
    background-color: ${!disabled
      ? theme.colors.surface.bright.background[theme.states.hover]
      : theme.colors.surface.bright.background[0]};
  }
`;

/** Meses no intervalo */
const getInRangeStyles = (theme: DefaultTheme) => css`
  background-color: ${theme.colors.secondary.container[0]};
  color: ${theme.colors.surface.onSurface[0]};

  &:hover {
    background-color: ${theme.colors.secondary.container[theme.states.hover]};
  }
`;

/** Meses selecionado */
const getSelectedStyles = (theme: DefaultTheme) => css`
  border-radius: 0;
  background-color: ${theme.colors.secondary.container[0]};

  span {
    background-color: ${theme.colors.secondary.default[0]};
    color: ${theme.colors.secondary.on[0]};
    border-radius: ${theme.borderRadius.full};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;

    &:hover {
      background-color: ${theme.colors.secondary.default[theme.states.hover]};
    }
  }
`;

/** Primeiro mês selecionado do intervalo */
const getStartStyles = (theme: DefaultTheme) => css`
  border-top-left-radius: ${theme.borderRadius.full};
  border-bottom-left-radius: ${theme.borderRadius.full};
`;

/** Último mês selecionado do intervalo */
const getEndStyles = (theme: DefaultTheme) => css`
  border-top-right-radius: ${theme.borderRadius.full};
  border-bottom-right-radius: ${theme.borderRadius.full};
`;

export const Button = styled.button<IButton>`
  width: 48px;
  height: 48px;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ isSelected, isInRange, isStart, isEnd, disabled, theme }) =>
    !isSelected &&
    !isInRange &&
    !isStart &&
    !isEnd &&
    getDefaultStyles(theme, !!disabled)}

  ${({ isSelected, theme }) => isSelected && getSelectedStyles(theme)};
  ${({ isStart, theme }) => isStart && getStartStyles(theme)};
  ${({ isEnd, theme }) => isEnd && getEndStyles(theme)};
  ${({ isInRange, theme }) => isInRange && getInRangeStyles(theme)};
`;
