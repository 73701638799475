/** URL do website da Hand Talk */
export const websiteHT = 'https://handtalk.me/';

/** URL de contato do suporte */
export const supportUrl = 'https://suporte.handtalk.me/kb/pt-br/form/4680/';

/** Link para página da base de conhecimento */
export const knowledgeBaseURL =
  'https://suporte.handtalk.me/kb/category/dashboard-do-cliente';

/** E-mail do time de Sucesso do Cliente */
export const emailCS = 'mailto:cs@handtalk.me';

/** URL da guia de comunicação */
export const engagementUrl =
  'https://docs.google.com/presentation/d/1pNGKZ2x5ayix225O3Yg212_iLHk2_LTb9k_VmzCZD0w/present?slide=id.g6e3073281d_1_264';

/** URL da página da Hand Talk no Facebook */
export const FacebookHT = 'https://www.facebook.com/handtalkBR/';

/** URL da página da Hand Talk no Linkedin */
export const LinkedinHT = 'https://www.linkedin.com/company/hand-talk';

/** URL da página da Hand Talk no Instagram */
export const InstagramHT = 'https://www.instagram.com/handtalkbr/';

/** URL da página da Hand Talk no Youtube */
export const YoutubeHT = 'https://www.youtube.com/user/HandTalkTV';

/** URL do blog em português */
export const blogBR = 'https://www.handtalk.me/br/blog/';

/** URL do blog em inglês */
export const blogEN = 'https://www.handtalk.me/en/blog/';

/** Dashboard URL */
export const dashboardUrl = 'https://dashboard.handtalk.me/';
