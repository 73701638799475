/** Libs */
import React from 'react';

import { useTranslation } from 'react-i18next';

/** Styles */
import { Container, ContainerContent } from './styles';

/** Components */
import { YIcon, YIconButton } from '@hand-talk/yotta-components';

interface ICalendarHeader {
  onPrev?: () => void;
  onNext?: () => void;
  content: React.ReactNode;
  paddingDefault?: boolean;
}

const CalendarHeader: React.FC<ICalendarHeader> = ({
  onPrev,
  onNext,
  content,
  paddingDefault,
}) => {
  const { t: translateYCalendar } = useTranslation('YCalendar');

  return (
    <Container paddingDefault={paddingDefault}>
      <YIconButton
        data-mode={translateYCalendar('Previous')}
        aria-label={translateYCalendar('Previous')}
        title={translateYCalendar('Previous')}
        onClick={onPrev}
        children={<YIcon name="chevronLeft" />}
      />
      <ContainerContent>{content}</ContainerContent>
      <YIconButton
        data-mode={translateYCalendar('Next')}
        aria-label={translateYCalendar('Next')}
        title={translateYCalendar('Next')}
        onClick={onNext}
        children={<YIcon name="chevronRight" />}
      />
    </Container>
  );
};

export default CalendarHeader;
