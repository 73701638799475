import { Alert } from '../../../../components/Alert';
import { SuccessAlertContainer } from './styles';

interface SuccessAlertProps {
  title: string;
  onCloseAlert: () => void;
}

export const SuccessAlert = ({ title, onCloseAlert }: SuccessAlertProps) => {
  return (
    <SuccessAlertContainer>
      <Alert type="success" title={title as string} handleClose={onCloseAlert} />
    </SuccessAlertContainer>
  );
};
