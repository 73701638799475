/** Libs */
import React from 'react';

import { YInput } from '@hand-talk/yotta-components';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Button, Container, Inputs } from './styles';

/** Components */
import CalendarHeader from '../CalendarHeader';

/** Hooks */
import useMonthRange from '../utils/useMonthRange';
import useLocale from '../../../hooks/useLocale';

/** Services */
import { Timestamp } from '../../../../infra/services/Timestamp';
import { formatMonthAndYear } from '../../../../infra/utils/dateUtils';

interface ICalendarMonth {
  selected?: [Timestamp, Timestamp];
  onChange: (value: [Timestamp, Timestamp]) => void;
  onDisableDay: (dayTimestamp: Timestamp) => boolean;
}

const CalendarMonth: React.FC<ICalendarMonth> = ({
  selected,
  onChange,
  onDisableDay,
}) => {
  const { t: translateYCalendar } = useTranslation('YCalendar');

  const { currentYear, monthData, handleMonthClick, handlePreviousYear, handleNextYear } =
    useMonthRange({
      selected,
      onChange,
      onDisableDay,
      messages: {
        invalidPeriod: translateYCalendar('invalidPeriod'),
      },
    });

  const locale = useLocale();

  const startDate = selected?.[0]
    ? formatMonthAndYear(selected[0], locale)
    : translateYCalendar('noDate');

  const endDate = selected?.[1]
    ? formatMonthAndYear(selected[1], locale)
    : translateYCalendar('noDate');

  return (
    <>
      <Inputs>
        <YInput
          inputProps={{
            type: 'text',
            value: startDate,
            readOnly: true,
            placeholder: translateYCalendar('noDate'),
          }}
          labelText={translateYCalendar('startMonthLabel')}
        />

        <YInput
          inputProps={{
            type: 'text',
            value: endDate,
            readOnly: true,
            placeholder: translateYCalendar('noDate'),
          }}
          labelText={translateYCalendar('endMonthLabel')}
        />
      </Inputs>

      <CalendarHeader
        paddingDefault
        onPrev={handlePreviousYear}
        onNext={handleNextYear}
        content={<>{currentYear}</>}
      />

      <Container>
        {monthData.map(
          ({
            monthName,
            isSelected,
            isInRange,
            isValid,
            ariaLabel,
            index,
            isStart,
            isEnd,
          }) => (
            <Button
              key={monthName}
              isSelected={!!isSelected}
              isInRange={!!isInRange}
              isStart={!!isStart}
              isEnd={!!isEnd}
              onClick={() => isValid && handleMonthClick(index)}
              aria-label={ariaLabel}
              title={ariaLabel}
              disabled={!isValid}
            >
              <span aria-hidden="true">{monthName.slice(0, 3)}</span>
            </Button>
          )
        )}
      </Container>
    </>
  );
};

export default CalendarMonth;
