import { IRemoteConfigLayout } from './types';
import { GetAppearanceSection } from './AppearanceSection';
import { GetTranslatorSection } from './Translator';
import { GetAssistanceResourcesSection } from './AssistanceResources';
import { GetAdvancedFeaturesSection } from './AdvancedFeatures';

export const RemoteConfigFormList = (
  translate: (key: string) => string
): IRemoteConfigLayout[] => {
  return [
    GetAppearanceSection(translate),
    GetTranslatorSection(translate),
    GetAssistanceResourcesSection(translate),
    GetAdvancedFeaturesSection(translate),
  ];
};
