import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`;

export const LoadingContainer = styled.div`
  height: calc(100vh - 140px);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 800px;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[6]};
  margin: ${({ theme }) => theme.spacing[6]};
  margin-bottom: 104px !important;

  > section,
  div {
    margin: 0;
    padding: 0;
  }

  h2 {
    margin: 0;
    padding: ${({ theme }) => theme.spacing[5]};
  }
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[5]};

  padding: ${({ theme }) => theme.spacing[5]} !important;
`;
