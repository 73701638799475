/** Libs */
import React from 'react';
import { endOfDay, format, startOfDay, parse } from 'date-fns';

/** Components */
import YInputDate from '../YInputDate';

/** Services */
import { Timestamp } from '../../../infra/services/Timestamp';
import { IPeriodFilterProps } from './types';
import { IPeriod } from '../../../infra/services/interfaces';

export const PeriodFilter: React.FC<IPeriodFilterProps> = ({
  error,
  filterData,
  availablePeriod,
  disabled,
  onChange,
  onInvalidPeriod,
  disabledFilter,
  onChangeFilter,
  onRadioChange,
  periodReport,
  tagReport,
}) => {
  const handlePeriodChange = (range: [Timestamp, Timestamp]) => {
    const [startTimestamp, endTimestamp] = range;

    const start = startTimestamp && startOfDay(startTimestamp);
    const end = endTimestamp && endOfDay(endTimestamp);

    const changedPeriod: IPeriod = {
      startDate: start ? format(start, 'yyyy-MM-dd') : '',
      endDate: end ? format(end, 'yyyy-MM-dd') : '',
    };

    if (onChange) onChange(changedPeriod);
  };

  /** Define o periodo inicial */
  const range = React.useMemo((): [Timestamp, Timestamp] => {
    return [
      parse(filterData.period.startDate, 'yyyy-MM-dd', new Date()).getTime() as Timestamp,
      parse(filterData.period.endDate, 'yyyy-MM-dd', new Date()).getTime() as Timestamp,
    ];
  }, [filterData]);

  /** Define a data inicial e final do período */
  const { minDate, maxDate } = React.useMemo(() => {
    return {
      minDate: parse(availablePeriod.startDate, 'yyyy-MM-dd', new Date()),
      maxDate: parse(availablePeriod.endDate, 'yyyy-MM-dd', new Date()),
    };
  }, [availablePeriod]);

  const onDisableDay = React.useCallback(
    (dateTimestamp: Timestamp): boolean => {
      const date = new Date(dateTimestamp);
      return date < minDate || date > maxDate;
    },
    [minDate, maxDate]
  );

  return (
    <YInputDate
      error={error}
      disabled={disabled}
      onDisableDay={onDisableDay}
      onValueChange={handlePeriodChange}
      onInvalidPeriod={onInvalidPeriod}
      value={range}
      min={availablePeriod.startDate}
      max={availablePeriod.endDate}
      disabledFilter={disabledFilter}
      onChangeFilter={onChangeFilter}
      onRadioChange={onRadioChange}
      view={filterData.view}
      periodReport={periodReport}
      tagReport={tagReport}
    />
  );
};
