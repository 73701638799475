import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RemoteConfigLocalValues } from '../../../domain/types/remoteConfig';
import useProtected from '../../hooks/useProtected';
import useAuth from '../../hooks/useAuth';
import { useRemoteConfigContext } from '../../providers/RemoteConfigProvider/remoteConfigContextProvider';
import CardHelper from '../../components/CardHelper';
import { HeaderInputOption, IRemoteConfigLayout } from './constructor/types';
import { RemoteConfigFormList } from './constructor/formList';
import { CollapseCard } from '../../components/CollapseCard';
import { InputConfigCard } from './components/InputConfigCard';
import { CustomCardHeader } from './components/CustomHeader';
import { CustomFooter } from './components/CustomFooter';
import { RemoteConfigCard } from './components/RemoteConfigCard';
import { PreviewCard } from './components/PreviewCard';
import { SuccessAlert } from './components/SuccessAlert';
import Loading from '../../assets/svg/Loading';
import { advancedKeys } from '../../../core/common/remoteConfig';
import { ResourcesModal } from './components/ConfigModal/DisabledResources';
import { PublishModal } from './components/ConfigModal/Publish';
import { RestoreChangesModal } from './components/ConfigModal/RestoreChanges';
import { MessageAlertModal } from './components/ConfigModal/MessageAlert';
import { CollapseContainer, Container, Content, LoadingContainer } from './styles';

/** Contexts */
import { useReportContext } from '../../providers/ReportContext';

/** Services */
import { remoteConfigAccessEvent } from '../../../infra/services/analytics';

type ModalTypes = null | 'welcome' | 'restore' | 'publish' | 'resourcers';

const RemoteConfigPage = () => {
  const { t: translate } = useTranslation('RemoteConfig');
  const { checkAuthentication } = useProtected();
  const { accessKey } = useParams();
  const { authState } = useAuth();
  let [_, setSearchParams] = useSearchParams();
  const { report } = useReportContext();
  const {
    requestedRemoteConfig,
    getRemoteConfig,
    validateRemoteConfigFilling,
    saveConfig,
    parseLocalToRemoteConfig,
  } = useRemoteConfigContext();

  // currentStep: 1 -> changed | 2 -> preview | 3 -> saved
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);
  const [successAlert, setSuccessAlert] = useState<string | null>(null);
  const [modalType, setModalType] = useState<ModalTypes>(null);
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [remoteConfigValues, setRemoteConfigValues] = useState<RemoteConfigLocalValues>(
    {}
  );

  useEffect(() => {
    checkAuthentication();
  }, [authState]);

  useEffect(() => {
    if (requestedRemoteConfig.localFormat) {
      setRemoteConfigValues(requestedRemoteConfig.localFormat);
      setIsLoading(false);
      const verifyIsFilled = validateRemoteConfigFilling(
        requestedRemoteConfig.localFormat
      );
      setIsFilled(verifyIsFilled);
    }
  }, [requestedRemoteConfig]);

  useEffect(() => {
    if (reload && accessKey && authState.type === 'success') {
      loadingRemoteConfig(accessKey, authState.user.email as string);
      setReload(false);
      /** Dispara o evento para o Google Analytics */
      report?.info?.domain && remoteConfigAccessEvent(report.info.domain);
    }
  }, [reload, accessKey, authState]);

  const loadingRemoteConfig = async (accessKey: string, userEmail: string) => {
    setIsLoading(true);
    await getRemoteConfig(accessKey, userEmail);
  };

  const handleSaveConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await saveConfig(
        accessKey as string,
        (authState.type === 'success' && authState.user.email) as string,
        (authState.type === 'success' && authState.user.uid) as string,
        remoteConfigValues
      );
      if (result) {
        setSuccessAlert(translate('saveSuccess'));
      }
    } finally {
      setIsLoading(false);
      setModalType(null);
      handleBackStep();
      setReload(true);
    }
  }, [accessKey, authState, remoteConfigValues, translate, saveConfig]);

  const handleOpenPreview = useCallback(() => {
    setSearchParams({ step: 'preview' });
    parseLocalToRemoteConfig(remoteConfigValues);
    setCurrentStep(2);
  }, []);

  const handleBackStep = useCallback(() => {
    setSearchParams({});
    setCurrentStep((curr) => curr - 1);
  }, []);

  // Apply Disabled Resource and Enable advanced settings rules
  const applyChangeRoles = useCallback(
    (
      name: string,
      value: string | boolean | number,
      isFromModal: boolean = false
    ): RemoteConfigLocalValues | null => {
      let valuesToUpdate: RemoteConfigLocalValues = {};

      // Open Modal on disable resources card
      if (!isFromModal && name === 'addonsEnabled' && !value) {
        setModalType('resourcers');
        return null;
      }

      // Disabled advanced recomendation checkbox
      if (advancedKeys.includes(name)) valuesToUpdate['useAdvancedRecomendation'] = false;

      // If Enable advanced recomendation checkbox
      if (name === 'useAdvancedRecomendation')
        valuesToUpdate = {
          zIndex: 1000000,
          doNotTrack: false,
          isMajority: false,
        };

      return valuesToUpdate;
    },
    []
  );

  const handleChangeConfig = (
    name: string,
    value: string | boolean | number,
    isFromModal: boolean = false
  ) => {
    const validateChanges = applyChangeRoles(name, value, isFromModal);

    if (validateChanges === null) return;

    const newValues = {
      ...remoteConfigValues,
      [name]: value,
      ...validateChanges,
    };

    // Set Values, reset Modal and verify completed form
    setRemoteConfigValues(newValues);
    isFromModal && setModalType(null);
    setIsFilled(() => validateRemoteConfigFilling(newValues));
  };

  const getHeaderInputValue = (headerInput?: HeaderInputOption): boolean => {
    return headerInput
      ? remoteConfigValues[headerInput.inputId] !== undefined
        ? (remoteConfigValues[headerInput.inputId] as boolean)
        : (headerInput.initialState as boolean)
      : false;
  };

  return (
    <Container>
      {isLoading && (
        <LoadingContainer>
          <Loading big />
        </LoadingContainer>
      )}
      {currentStep === 2 && !isLoading && (
        <PreviewCard currentConfig={parseLocalToRemoteConfig(remoteConfigValues)} />
      )}
      {currentStep === 1 && !isLoading && (
        <Content>
          {RemoteConfigFormList(translate).map((formSection: IRemoteConfigLayout) => (
            <CardHelper
              key={formSection.title}
              title={formSection.title}
              customHeader={
                formSection.cardType !== 'collapse' ? (
                  <CustomCardHeader
                    title={formSection.title}
                    description={formSection.description}
                    headerInput={formSection.headerInput}
                    handleActiveHeader={handleChangeConfig}
                    value={getHeaderInputValue(formSection.headerInput)}
                  />
                ) : (
                  <></>
                )
              }
              buttonHelp={<></>}
            >
              <RemoteConfigCard title={formSection.title} cardType={formSection.cardType}>
                <>
                  {formSection.inputList.map((inputItem) => (
                    <InputConfigCard
                      key={inputItem.inputTitle}
                      handleChange={handleChangeConfig}
                      value={remoteConfigValues[inputItem.inputValue]}
                      hasTexture={!!remoteConfigValues['hasTexture']}
                      {...inputItem}
                    />
                  ))}
                  {Array.isArray(formSection.childrenCollapseCards) && (
                    <CollapseContainer>
                      {formSection.childrenCollapseCards.map((card) => (
                        <CollapseCard
                          key={card.title}
                          title={card.title}
                          isDisabled={!getHeaderInputValue(formSection.headerInput)}
                        >
                          <>
                            {card.inputList.map((inputItem) => (
                              <InputConfigCard
                                key={inputItem.inputTitle}
                                handleChange={handleChangeConfig}
                                value={remoteConfigValues[inputItem.inputValue]}
                                {...inputItem}
                              />
                            ))}
                          </>
                        </CollapseCard>
                      ))}
                    </CollapseContainer>
                  )}
                </>
              </RemoteConfigCard>
            </CardHelper>
          ))}
        </Content>
      )}
      {!isLoading && successAlert && (
        <SuccessAlert title={successAlert} onCloseAlert={() => setSuccessAlert(null)} />
      )}
      {!isLoading && !successAlert && (
        <CustomFooter
          {...{
            handleResetConfig: () => setModalType('restore'),
            handleOpenPreview,
            handleBackStep,
            handleSaveConfig: () => setModalType('publish'),
            currentStep,
            isFilled,
          }}
        />
      )}
      {modalType === 'welcome' && !isLoading && (
        <MessageAlertModal
          isModalOpen={modalType === 'welcome'}
          handleCloseModal={() => setModalType(null)}
          titleKey="firstAccessTitle"
          descriptionKey="firstAccessContent"
        />
      )}
      {modalType === 'restore' && !isLoading && (
        <RestoreChangesModal
          handleCloseModal={() => setModalType(null)}
          handleRestore={() => {
            setReload(true);
            setModalType(null);
          }}
          isModalOpen={modalType === 'restore'}
        />
      )}
      {modalType === 'publish' && !isLoading && (
        <PublishModal
          handleCloseModal={() => setModalType(null)}
          handleSaveConfig={handleSaveConfig}
          isModalOpen={modalType === 'publish'}
        />
      )}
      {modalType === 'resourcers' && !isLoading && (
        <ResourcesModal
          handleCloseModal={() => setModalType(null)}
          isModalOpen={modalType === 'resourcers'}
          onChangeConfig={handleChangeConfig}
        />
      )}
    </Container>
  );
};

export default RemoteConfigPage;
