import { AlertContainer, CloseButton } from './styles';
import infoIcon from '../../assets/svg/info.svg';
import alertIcon from '../../assets/svg/alert.svg';
import checkIcon from '../../assets/svg/check.svg';
import closeIcon from '../../assets/svg/close.svg';
import { useTranslation } from 'react-i18next';

interface AlertProps {
  type: 'error' | 'info' | 'success';
  title: string;
  handleClose?: () => void;
}

export const Alert = ({ type, title, handleClose }: AlertProps) => {
  const { t: translate } = useTranslation('AlertComponent');

  return (
    <AlertContainer type={type}>
      <img
        src={type === 'error' ? alertIcon : type === 'success' ? checkIcon : infoIcon}
        alt={translate(`${type}AltIcon`)}
      />
      <p>{title}</p>
      {handleClose && (
        <>
          <CloseButton onClick={() => handleClose()}>
            <img src={closeIcon} alt="" />
          </CloseButton>
        </>
      )}
    </AlertContainer>
  );
};
