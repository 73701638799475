import { IRemoteConfigLayout } from './types';

export const GetAdvancedFeaturesSection = (
  translate: (key: string) => string
): IRemoteConfigLayout => {
  return {
    title: translate('advancedFeaturesTitle'),
    cardType: 'collapse',
    inputList: [
      {
        inputTitle: '',
        inputDescription: translate('useRecomendationTitle'),
        inputType: 'checkbox',
        inputValue: 'useAdvancedRecomendation',
        defaultValue: true,
        inputOptions: [],
      },
      {
        inputTitle: translate('useRecomendationAlertTitle'),
        inputType: 'error',
        inputValue: '',
        inputOptions: [],
      },
      {
        inputTitle: translate('zIndexTitle'),
        inputType: 'text',
        inputValue: 'zIndex',
        hasBorderBottom: true,
        placeholder: '1000000',
        inputOptions: [],
      },
      {
        inputTitle: translate('doNotTrackTitle'),
        inputDescription: translate('doNotTrackDescription'),
        inputType: 'switch',
        inputValue: 'doNotTrack',
        hasBorderBottom: true,
        inputOptions: [],
      },
      {
        inputTitle: translate('isMajorityTitle'),
        inputDescription: translate('isMajorityDescription'),
        inputType: 'switch',
        inputValue: 'isMajority',
        inputOptions: [],
      },
    ],
  };
};
