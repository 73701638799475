/** Libs */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { YThemeProvider, lightTheme } from '@hand-talk/yotta-core';
import { useInjection } from 'inversify-react';

/** Services */
import { usePreferencies } from './view/hooks/usePreferencies';
import { setLanguage } from './infra/i18n';
import * as analytics from './infra/services/analytics';
import Config from './infra/apis/Config';

/** Styles */
import { GlobalStyle } from './view/assets/styles/GlobalStyle';

/** Routes and Layouts */
import { PrimaryLayoutRoutes } from './view/routes';
import { PrimaryLayout } from './view/layouts/PrimaryLayout';

/** Contexts */
import { RemoteConfigContextProvider } from './view/providers/RemoteConfigProvider/remoteConfigContextProvider';
import { ReportProvider } from './view/providers/ReportContext';

const insertPluginScript = () => {
  const isBetaUrl = window.location.href.includes('client-dashboard-beta.web.app');

  const existingScript = document.getElementById('custom-ht-script');

  if (existingScript) return;
  const script = document.createElement('script');
  script.id = 'custom-ht-script';
  script.type = 'text/javascript';

  const token = isBetaUrl
    ? 'df03afabdc98e53eef377512ed669a7b'
    : '894ad581fb6306c7363cb9c32a5dd9dd';
  script.textContent = `
    var ht = new HT({
      token: '${token}',
      align: "top"
    });
  `;

  const body = document.getElementsByTagName('body')[0];
  body.appendChild(script);
};

const App = () => {
  const { language, noAnalytics } = usePreferencies();
  const config = useInjection(Config);

  useEffect(() => {
    insertPluginScript();
  }, []);

  const RedirectToNotFound = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
      navigate('/not-found', { replace: true });
    }, [navigate]);

    return null;
  };

  React.useEffect(() => {
    if (noAnalytics) return;

    analytics.init(config.ga4Tag);
  }, [config.ga4Tag, noAnalytics]);

  React.useEffect(() => {
    setLanguage(language);
  }, [language]);

  return (
    <YThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <ReportProvider>
        <RemoteConfigContextProvider>
          <Router>
            <Routes>
              <Route>
                {PrimaryLayoutRoutes.map(({ path, component, ...props }) => (
                  <Route
                    key={path}
                    path={path}
                    element={<PrimaryLayout {...props}>{component}</PrimaryLayout>}
                  />
                ))}
              </Route>
              <Route path="*" element={<RedirectToNotFound />} />
            </Routes>
          </Router>
        </RemoteConfigContextProvider>
      </ReportProvider>
    </YThemeProvider>
  );
};

export default App;
