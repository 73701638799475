import { useTranslation } from 'react-i18next';
import { YButton } from '@hand-talk/yotta-components';
import { Alert } from '../../../../components/Alert';
import { FooterContainer } from './styles';

interface CustomFooterProps {
  currentStep: number;
  isFilled: boolean;
  handleResetConfig: () => void;
  handleOpenPreview: () => void;
  handleBackStep: () => void;
  handleSaveConfig: () => void;
}

export const CustomFooter = ({
  currentStep,
  isFilled,
  handleOpenPreview,
  handleBackStep,
  handleResetConfig,
  handleSaveConfig,
}: CustomFooterProps) => {
  const { t: translate } = useTranslation('RemoteConfig');

  return (
    <FooterContainer>
      {!isFilled ? (
        <Alert type="info" title={translate('emptyConfigAlert')} />
      ) : (
        <>
          <YButton
            variation="text"
            onClick={() => (currentStep === 1 ? handleResetConfig() : handleBackStep())}
          >
            {currentStep === 1 ? translate('resetBtnTitle') : translate('backBtnTitle')}
          </YButton>
          <YButton
            variation="fill"
            onClick={() => (currentStep === 1 ? handleOpenPreview() : handleSaveConfig())}
          >
            {currentStep === 1
              ? translate('previewBtnTitle')
              : translate('publishBtntitle')}
          </YButton>
        </>
      )}
    </FooterContainer>
  );
};
