import { useTranslation } from 'react-i18next';
import { ConfigModal } from '.';

interface ResourcesModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  onChangeConfig: (name: string, value: boolean, isFromModal: boolean) => void;
}

export const ResourcesModal = ({
  isModalOpen,
  handleCloseModal,
  onChangeConfig,
}: ResourcesModalProps) => {
  const { t: translate } = useTranslation('RemoteConfig');

  return (
    <ConfigModal
      {...{
        isModalOpen,
        hasFooter: true,
        buttonLeftLabel: translate('resourcesAlertModalLeftBtn'),
        buttonRightLabel: translate('resourcesAlertModalRightBtn'),
        handleCloseModal,
        handleClickRight: handleCloseModal,
        handleClickLeft: () => onChangeConfig('addonsEnabled', false, true),
      }}
      title={translate('resourcesAlertModalTitle')}
      textContent={<p>{translate('resourcesAlertModal')}</p>}
    />
  );
};
