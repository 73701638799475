import { isMobile } from '../../../../../infra/services/helper';
import Translation from '../../../../assets/svg/Translation';
import wordsCountRetroHugo from '../../../../assets/svg/wordsCountRetroHugo.svg';
import hugoSignalingLove from '../../../../assets/svg/hugoSignalingLove.svg';
import {
  Container,
  Content,
  Footer,
  Header,
  ImageLeftContainer,
  ImageRightContainer,
  Paragraphs,
} from './styles';
import { useTranslation } from 'react-i18next';

interface WordsSlideProps {
  isPrintActive?: boolean;
  data: number | string;
}

export const WordsSlide = ({ isPrintActive, data }: WordsSlideProps) => {
  const { t: translate } = useTranslation('WordsSlide');

  const footerHighlighted = [
    { content: 'WordsFourthBody', highlighted: 'WordsFourthHighlighted' },
  ];

  return (
    <Container isPrintActive={isPrintActive}>
      {!isPrintActive && (
        <ImageLeftContainer>
          <img src={hugoSignalingLove} alt={translate('WordsCountRetroHugo')} />
        </ImageLeftContainer>
      )}
      <Content>
        <Header>{translate('WordsTitle')}</Header>
        <Paragraphs>
          <p>{translate('WordsFirstBody')}</p>
          <p>{translate('WordsSecondBody')}</p>
          <strong>{data}</strong>
          <p>{translate('WordsTertiaryBody')}</p>
        </Paragraphs>
        <Footer>
          {footerHighlighted.map(({ content, highlighted }) => {
            const [initial, final] = translate(highlighted)
              ? translate(content).split(translate(highlighted))
              : [translate(content)];

            return (
              <p key={content}>
                {initial}
                {highlighted && <strong>{translate(highlighted)}</strong>}
                {final && final}
              </p>
            );
          })}
        </Footer>
      </Content>
      <ImageRightContainer>
        {isMobile() || isPrintActive ? (
          <img src={wordsCountRetroHugo} alt={translate('WordsCountRetroHugo')} />
        ) : (
          <Translation alt={translate('TranslationAlt')} />
        )}
      </ImageRightContainer>
    </Container>
  );
};
