/** Libs */
import React from 'react';

/** Interfaces */
import { Timestamp } from '../../../infra/services/Timestamp';

/** Utils */
import { useDatepickerRange } from './utils/useDatepickerRange';
import { useHighlightedMode } from './utils/useHighlightedMode';
import { useSet } from './utils/useSet';
import { useCalendarMonth } from './utils/useCalendarMonth';

/** Components */
import CalendarButtonDay from './CalendarButtonDay';
import CalendarContainer from './CalendarContainer';

export type ICalendarDay = Parameters<typeof useDatepickerRange>[0];

const CalendarDay: React.FC<ICalendarDay> = (props) => {
  const { selected, onDisableDay } = props;

  const monthTimestamp = selected?.[0] ? selected[0] : (Date.now() as Timestamp);

  const { currentMonthTimestamp, onPrevMonth, onNextMonth } =
    useCalendarMonth(monthTimestamp);

  const { nextSelected, highlightedDay, onClick, onHighlight } =
    useDatepickerRange(props);

  const nextSelectedSet = useSet(nextSelected);
  const getHighlightedMode = useHighlightedMode(nextSelected, highlightedDay);

  return (
    <CalendarContainer
      monthTimestamp={currentMonthTimestamp}
      onPrevMonth={onPrevMonth}
      onNextMonth={onNextMonth}
    >
      {(day) => (
        <CalendarButtonDay
          key={String(day.timestamp)}
          highlightedMode={getHighlightedMode(day.timestamp)}
          isSelected={nextSelectedSet.has(day.timestamp)}
          isCurrentMonth={day.isSameMonth}
          dayTimestamp={day.timestamp}
          onClick={onClick}
          onPointerEnter={onHighlight}
          onDisable={onDisableDay}
        >
          {day.displayDay}
        </CalendarButtonDay>
      )}
    </CalendarContainer>
  );
};

const CalendarDayMemo = React.memo(CalendarDay);
export { CalendarDayMemo as CalendarDay };
