import { useTranslation } from 'react-i18next';
import { ConfigModal } from '.';

interface MessageAlertModalProps {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  titleKey: string;
  descriptionKey: string;
}

export const MessageAlertModal = ({
  handleCloseModal,
  isModalOpen,
  titleKey,
  descriptionKey,
}: MessageAlertModalProps) => {
  const { t: translate } = useTranslation('RemoteConfig');

  return (
    <ConfigModal
      {...{
        isModalOpen,
        hasFooter: false,
        handleCloseModal,
      }}
      title={translate(titleKey)}
      textContent={
        <>
          <p>{translate(descriptionKey)}</p>
        </>
      }
    />
  );
};
