// Libs
import { injectable } from 'inversify';

// Domain
import { RemoteConfigEntity } from '../../entities/RemoteConfigEntity';
import { RemoteConfigSchema } from '../../schemas/remoteConfig/RemoteConfigSchema';
import { RemoteConfigLocalValues } from '../../types/remoteConfig';
import { parseToOriginalPrimitiveType } from '../../../infra/services/primitiveTypesHelper';
import {
  ColorControlAddonsSchema,
  FontControlAddonsSchema,
  NavigationAddonsSchema,
} from '../../schemas/remoteConfig/PluginConfigSchema';

// Core
import {
  addonsKeys,
  advancedKeys,
  colorControlAddonsKeys,
  commonsConfigKeys,
  fontControlAnddonsKeys,
  navigationAddonsKeys,
} from '../../../core/common/remoteConfig';

@injectable()
export default class ParseRemoteConfigUseCase {
  public parseFromLocalConfig(
    localData: RemoteConfigLocalValues,
    remoteConfig: RemoteConfigEntity | null
  ): RemoteConfigEntity {
    try {
      const createConfig = (keys: string[]) => {
        return keys.reduce((acc: RemoteConfigLocalValues, key) => {
          // verify and return correct original type (number, string or boolean)
          acc[key] = parseToOriginalPrimitiveType(localData[key]);
          return acc;
        }, {});
      };

      const parsedConfig: RemoteConfigSchema = {
        id: remoteConfig?.id,
        sid: '',
        isMajority: !!localData['isMajority'],
        isActive: false,
        config: {
          ...createConfig(commonsConfigKeys),
          ...createConfig(advancedKeys),
          addonsMap: {
            fontControl: createConfig(fontControlAnddonsKeys) as FontControlAddonsSchema,
            navigation: createConfig(navigationAddonsKeys) as NavigationAddonsSchema,
            colorControl: createConfig(
              colorControlAddonsKeys
            ) as ColorControlAddonsSchema,
          },
        },
      };

      return new RemoteConfigEntity(parsedConfig.id ?? '', parsedConfig);
    } catch (error: any) {
      throw new Error(
        `Erro ao converter configurações remotas: ${error.message as string}`
      );
    }
  }

  public parseToLocalConfig(
    remoteConfig: RemoteConfigEntity | null
  ): RemoteConfigLocalValues {
    try {
      const defaultConfigValues = addonsKeys.reduce(
        (acc: RemoteConfigLocalValues, key) => {
          acc[key] = true;
          return acc;
        },
        { addonsEnabled: false }
      );

      if (!remoteConfig || !remoteConfig.config || !remoteConfig.config) {
        return defaultConfigValues;
      }

      const formattedConfig = remoteConfig.toObject();
      defaultConfigValues.hasTexture = formattedConfig.hasTexture ?? false;

      const { config } = formattedConfig;

      if (!config?.addonsMap) {
        return defaultConfigValues;
      }

      const { addonsMap } = config;

      delete config.addonsMap;

      const response: RemoteConfigLocalValues = {
        hasTexture: formattedConfig.hasTexture ?? false,
        isMajority: formattedConfig.isMajority,
        ...(config as RemoteConfigLocalValues),
        ...addonsMap?.fontControl,
        ...addonsMap?.colorControl,
        ...addonsMap?.navigation,
      };

      let useAdvancedRecomendation = true;

      advancedKeys.map((row) => {
        if (response[row]) {
          if (row === 'zIndex' && response[row] != 1000000) {
            useAdvancedRecomendation = false;
          } else if (['doNotTrack', 'isMajority'].includes(row)) {
            useAdvancedRecomendation = false;
          }
        }
      });

      return { ...response, useAdvancedRecomendation };
    } catch (error: any) {
      throw new Error(
        `Erro ao converter configurações remotas: ${error.message as string}`
      );
    }
  }
}
