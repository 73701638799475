import styled from 'styled-components';

interface ISectionCard {
  flexRow: boolean;
  gap?: 'full' | 'sm';
  noVerticalPadding?: boolean;
}

export const SectionCard = styled.div<ISectionCard>`
  display: flex;
  gap: ${({ theme, gap }) => (gap === 'sm' ? theme.spacing[3] : 0)};
  flex-direction: ${({ flexRow }) => (flexRow ? 'row' : 'column')};
  justify-content: ${({ flexRow, gap }) =>
    flexRow && gap === 'full' ? 'space-between' : 'flex-start'};
  align-items: ${({ flexRow }) => (flexRow ? 'center' : 'flex-start')};

  &.--borderBottom {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.low[0]};
  }

  h6 {
    font-size: ${({ theme }) => theme.typography.title.sm};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.surface.onSurface[0]};
  }

  padding: ${({ theme, noVerticalPadding }) =>
    noVerticalPadding ? `0 ${theme.spacing[5]}` : theme.spacing[5]} !important;
`;

export const InputTitleSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[3]};
  flex-direction: column;
`;

export const InputCardSection = styled.section`
  span span span {
    height: 24px;
    width: 24px;
  }
`;

export const SwitchInputContent = styled.div`
  label span {
    width: 52px;
    height: 32px;
  }
`;
