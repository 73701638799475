/** Libs */
import { format } from 'date-fns';

/**
 * Retorna o nome do mês formatado com base no timestamp e no locale atual.
 */
export const getFormattedMonth = (timestamp: number, locale: Locale): string => {
  const date = new Date(timestamp);
  return format(date, 'MMMM', { locale }).replace(/^\w/, (c) => c.toUpperCase());
};

/**
 * Retorna o ano formatado com base no timestamp e no locale atual.
 */
export const getFormattedYear = (timestamp: number, locale: Locale): string => {
  const date = new Date(timestamp);
  return format(date, 'yyyy', { locale });
};

/**
 * Formata uma data no formato "Mês de Ano" abreviado, considerando o idioma atual.
 */
export const formatMonthAndYear = (dateTimestamp: number, locale: Locale): string => {
  const date = new Date(dateTimestamp);
  const formattedDate = format(date, 'MMM yyyy', { locale });
  const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  return locale.code === 'pt' ? capitalizedDate.replace(' ', ' de ') : capitalizedDate;
};
