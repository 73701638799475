import { IRemoteConfigLayout } from './types';

export const GetAssistanceResourcesSection = (
  translate: (key: string) => string
): IRemoteConfigLayout => {
  return {
    title: translate('assistanceResourceTitle'),
    description: translate('assistanceResourceDescription'),
    headerInput: {
      initialState: true,
      inputId: 'addonsEnabled',
      inputType: 'switch',
    },
    inputList: [],
    childrenCollapseCards: [
      {
        title: translate('fontControlTitle'),
        initialState: 'close',
        inputList: [
          {
            inputTitle: translate('fontSizeTitle'),
            inputValue: 'fontSize',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('fontSizeDescription'),
          },
          {
            inputTitle: translate('textStyleTitle'),
            inputValue: 'textStyle',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('textStyleDescription'),
          },
          {
            inputTitle: translate('highlightedLettersTitle'),
            inputValue: 'highlightLetters',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('highlightedLettersDescription'),
          },
          {
            inputTitle: translate('spaceBetweenLinesTitle'),
            inputValue: 'letterSpacing',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('spaceBetweenLinesDescription'),
          },
          {
            inputTitle: translate('spaceBetweenLettersTitle'),
            inputValue: 'lineHeight',
            inputType: 'switch',
            inputOptions: [],
            inputDescription: translate('spaceBetweenLettersDescription'),
          },
        ],
      },
      {
        title: translate('navigationTitle'),
        initialState: 'close',
        inputList: [
          {
            inputTitle: translate('websiteReaderTitle'),
            inputValue: 'pageSpeech',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('websiteReaderDescription'),
          },
          {
            inputTitle: translate('readerModeTitle'),
            inputValue: 'readerMode',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('readerModeDescription'),
          },
          {
            inputTitle: translate('readingMaskTitle'),
            inputValue: 'readingMask',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('readingMaskDescription'),
          },
          {
            inputTitle: translate('readingGuideTitle'),
            inputValue: 'readingGuide',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('readingGuideDescription'),
          },
          {
            inputTitle: translate('linksHighlightTitle'),
            inputValue: 'highlightLinks',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('linksHighlightDescription'),
          },
          {
            inputTitle: translate('pageStructureTitle'),
            inputValue: 'pageStructure',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('pageStructureDescription'),
          },
          {
            inputTitle: translate('magnifierTitle'),
            inputValue: 'magnifier',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('magnifierDescription'),
          },
          {
            inputTitle: translate('hideImagesTitle'),
            inputValue: 'hideImages',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('hideImagesDescription'),
          },
          {
            inputTitle: translate('highlightHeadingsTitle'),
            inputValue: 'highlightHeadings',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('highlightHeadingsDescription'),
          },
          {
            inputTitle: translate('pauseAnimationsTitle'),
            inputValue: 'pauseAnimations',
            inputType: 'switch',
            inputOptions: [],
            inputDescription: translate('pauseAnimationsDescription'),
          },
        ],
      },
      {
        title: translate('colorControlTitle'),
        initialState: 'close',
        inputList: [
          {
            inputTitle: translate('contrastModeTitle'),
            inputValue: 'contrastMode',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('contrastModeDescription'),
          },
          {
            inputTitle: translate('saturationModeTitle'),
            inputValue: 'saturationMode',
            inputType: 'switch',
            hasBorderBottom: true,
            inputOptions: [],
            inputDescription: translate('saturationModeDescription'),
          },
          {
            inputTitle: translate('pageColorsTitle'),
            inputValue: 'pageColors',
            inputType: 'switch',
            inputOptions: [],
            inputDescription: translate('pageColorsDescription'),
          },
        ],
      },
    ],
  };
};
