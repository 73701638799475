import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { YIcon, YTabPane, YTabs } from '@hand-talk/yotta-components';
import { previewIframeContent, PreviewIframeStyles } from '../PreviewIframe';
import { RemoteConfigSchema } from '../../../../../domain/schemas/remoteConfig/RemoteConfigSchema';
import { PreviewCardContainer, PreviewContent, PreviewHeader } from './styles';

interface PreviewCardProps {
  currentConfig: RemoteConfigSchema;
}

export const PreviewCard = ({ currentConfig }: PreviewCardProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { t: translate } = useTranslation('RemoteConfig');
  const [currentTab, setCurrentTab] = useState<string>('desktop');
  const headerOptions = [
    { id: 'desktop', label: 'Desktop', iconName: 'desk' },
    { id: 'mobile', label: 'Mobile', iconName: 'mobile' },
  ];

  const applyIframeAndStyles = () => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        // Escreve o conteúdo inicial apenas se o iframe estiver vazio
        if (!iframeDocument.body.innerHTML) {
          iframeDocument.open();
          iframeDocument.write(
            previewIframeContent(currentTab === 'mobile', currentConfig)
          );

          // Cria e aplica os estilos no iframe
          const styleTag = iframeDocument.createElement('style');
          styleTag.textContent = PreviewIframeStyles;
          iframeDocument.head.appendChild(styleTag);
          iframeDocument.close();
        }
      }
    }
  };

  const changeIframeContent = () => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;
      if (iframeDocument) {
        const body = iframeDocument.body;
        const isMobile = currentTab === 'mobile';

        // Atualiza o id do corpo para refletir a mudança entre desktop e mobile
        body.id = `config_preview_${isMobile ? 'mobile' : 'desktop'}`;

        // Remove as cards adicionais (caso o estado mude para mobile)
        const cardContainer = body.querySelectorAll('.card3, .card4, .card5');
        cardContainer.forEach((el) => el.remove());

        // Se for desktop, adiciona as cards extras
        if (!isMobile) {
          const card3 = document.createElement('div');
          card3.classList.add('card3');
          body.appendChild(card3);

          const card4 = document.createElement('div');
          card4.classList.add('card4');
          body.appendChild(card4);

          const card5 = document.createElement('div');
          card5.classList.add('card5');
          body.appendChild(card5);
        }
      }
    }
  };

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDocument = iframeRef.current.contentDocument;

      if (iframeDocument) {
        !iframeDocument.body.innerHTML ? applyIframeAndStyles() : changeIframeContent();
      }
    }
  }, [currentTab]);

  return (
    <PreviewCardContainer>
      <PreviewHeader>
        <YTabs activeTabId={currentTab} hasBorder onTabChange={setCurrentTab}>
          {headerOptions.map((option) => (
            <YTabPane
              key={option.id}
              aria-label={`${translate(`${option.label}TabAriaLabel`)}`}
              id={option.id}
              label={option.label}
              title={option.label}
              icon={<YIcon name={option.iconName as 'desk' | 'mobile'} />}
            >
              <></>
            </YTabPane>
          ))}
        </YTabs>
      </PreviewHeader>
      <PreviewContent isMobile={currentTab === 'mobile'}>
        <iframe ref={iframeRef} title="Preview Iframe" width="1250px" />
      </PreviewContent>
    </PreviewCardContainer>
  );
};
