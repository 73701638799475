import React from 'react';

/** Services */
import { Report } from '../../infra/services/interfaces';

interface ReportContextData {
  /** Relatório atual */
  report: Report | null;
  /** Função para atualizar o relatório */
  setReport: (report: Report) => void;
}

const ReportContext = React.createContext<ReportContextData | null>(null);

export const ReportProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [report, setReport] = React.useState<Report | null>(null);

  return (
    <ReportContext.Provider value={{ report, setReport }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = (): ReportContextData => {
  const context = React.useContext(ReportContext);

  if (!context) {
    throw new Error('useReportContext deve ser usado dentro de um ReportProvider');
  }

  return context;
};
