import { useTranslation } from 'react-i18next';
import { ConfigModal } from '.';

interface RestoreChangesModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleRestore: () => void;
}

export const RestoreChangesModal = ({
  isModalOpen,
  handleCloseModal,
  handleRestore,
}: RestoreChangesModalProps) => {
  const { t: translate } = useTranslation('RemoteConfig');

  return (
    <ConfigModal
      {...{
        isModalOpen,
        hasFooter: true,
        buttonLeftLabel: translate('restoreAlertModalLeftBtn'),
        buttonRightLabel: translate('restoreAlertModalRightBtn'),
        handleCloseModal,
        handleClickLeft: handleCloseModal,
        handleClickRight: handleRestore,
      }}
      title={translate('restoreAlertModalTitle')}
      textContent={
        <>
          <p>{translate('restoreAlertModal')}</p>
        </>
      }
    />
  );
};
