import { IRemoteConfigLayout } from './types';

export const GetAppearanceSection = (
  translate: (key: string) => string
): IRemoteConfigLayout => {
  return {
    title: translate('appearanceTitle'),
    inputList: [
      {
        inputTitle: translate('buttonSideTitle'),
        inputType: 'radio',
        inputValue: 'side',
        hasBorderBottom: true,
        inputOptions: [
          {
            value: 'left',
            label: translate('appearanceOptionLeft'),
            optionTitle: `${translate('optionTitle')} ${translate(
              'appearanceOptionLeft'
            )}`,
          },
          {
            value: 'right',
            label: translate('appearanceOptionRight'),
            optionTitle: `${translate('optionTitle')} ${translate(
              'appearanceOptionRight'
            )}`,
          },
        ],
      },
      {
        inputTitle: translate('buttonColorTitle'),
        inputType: 'select',
        inputValue: 'colorButton',
        placeholder: translate('buttonColorPlaceholder'),
        hasBorderBottom: true,
        inputOptions: [
          {
            value: 'a11yColorMain',
            name: translate('buttonColorBlue'),
          },
          {
            value: 'a11yColorLight',
            name: translate('buttonColorBlueLight'),
          },
          {
            value: 'neutralLowMain',
            name: translate('buttonColorGray'),
          },
        ],
      },
      {
        inputTitle: translate('themeColorTitle'),
        inputType: 'radio',
        inputValue: 'highContrast',
        inputOptions: [
          {
            value: 'false',
            label: translate('themeColorLight'),
            optionTitle: `${translate('optionTitle')} ${translate('themeColorLight')}`,
          },
          {
            value: 'true',
            label: translate('themeColorDark'),
            optionTitle: `${translate('optionTitle')} ${translate('themeColorDark')}`,
          },
        ],
      },
    ],
  };
};
