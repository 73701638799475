/** Libs */
import styled from 'styled-components';

export const Container = styled.div<{ paddingDefault?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: ${({ paddingDefault, theme }) =>
    paddingDefault ? `${theme.spacing[4]} 0 0` : `24px ${theme.spacing[4]}`};
`;

export const ContainerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${({ theme }) => theme.typography.paragraph.mdBold};
  color: ${({ theme }) => theme.colors.surface.onSurface[0]};
`;
